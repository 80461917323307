import React from "react";
import { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import Paquetes from "../components/Paquetes";
import "../assets/css/Ofertas.css";

function Ofertas() {
  const { seteos } = useContext(Contexto);

  const [paquetes, setPaquetes] = useState([]);
  useEffect(() => {
    let paquetesListado = [];
    if (seteos) {
      let claves = Object.keys(seteos.paquetes);
      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        paquetesListado.push(seteos.paquetes[clave]);
      }
      setPaquetes(paquetesListado);
    }
  }, [seteos]);

  return (
    <>
      {paquetes &&
        /*eslint-disable */
        paquetes.map((item, i) => {
          if (item.estado) {
            /*eslint-disable */
            return <Paquetes key={i} {...item}></Paquetes>;
          }
        })}
    </>
  );
}

export default Ofertas;
