import "../assets/css/Records.css";
import Contexto from "../context/Contexto";
import { useContext, useState, useEffect } from "react";

function Records() {
  const { mandarMensajes, winner, intento, guardarGanador, premios } =
    useContext(Contexto);
  const [becas, setBecas] = useState([]);
  useEffect(() => {
    setBecas(premios?.llaves);
  }, [premios]);

  return (
    <>
      <section className="ganadores">
        <h1 className="ganadores-h1">High Scores</h1>
        <div className="ganadores-lista">
          <div className="ganadores-nombres">
            <ul className="ganadores-columnaNombres">
              {becas &&
                becas.map((item, i) => (
                  <li>
                    {i + 1 + "   "}
                    {item.nombre}
                  </li>
                ))}
            </ul>
          </div>
          <div className="ganadores-fecha">
            <ul className="ganadores-columnaFecha">
              {becas &&
                becas.map((item) => (
                  <li className="ganadores-li">{item.fecha}</li>
                ))}
            </ul>
          </div>
        </div>
        <div id="ganadores-info">
          <p className="ganadores-pd">
            🡅 Dejanos tu nombre en la lista de ganadores 🡅
          </p>
        </div>
      </section>
    </>
  );
}

export default Records;
