import React from "react";
import renderHTML from "react-html-parser";

export default function Equipo(props) {
  let { img, nombre, txt } = props;
  return (
    <>
      <div className="col-md-4">
        <div className="reviews">
          <img src={img} alt="" className="reviews-img" />
          <div className="reviews-txt">
            <p className="">{renderHTML(txt)}</p>
            <p className="reviews-name">{nombre}</p>
          </div>
        </div>
      </div>
    </>
  );
}
