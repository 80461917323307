import llorando from "../assets/statics/llorando.png";
import React from "react";
import Contexto from "../context/Contexto";
import { useContext } from "react";
import "../assets/css/Examenes.css";

function ExamenesMal(props) {
  const { guardarYArrancar } = props;
  const { cliente, guardarDatos, usuario } = useContext(Contexto);

  return (
    <>
      <div className="contenedorExamenes">
        <h1>¡Oh, no! Malió sal</h1>

        <img src={llorando} alt="" className="esperar-imagen" />

        <h3>
          No aprobaste, pero no te precupes, podés volver a intentarlo en siete
          días. ¡A repasar los videos!
        </h3>
      </div>
    </>
  );
}

export default ExamenesMal;
