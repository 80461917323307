import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import { useLocation } from "react-router-dom";
import CarritoPaquete from "../components/CarritoPaquete";
import CarritoItem from "../components/CarritoItem";
import MercadoPago from "../components/MercadoPago";
import "../assets/css/Carrito.css";
import Piza from "../assets/statics/srplata.png";
import Regalo from "../assets/statics/regalo.png";
import {
  PayPalScriptProvider,
  PayPalButtons,
  BraintreePayPalButtons,
} from "@paypal/react-paypal-js";

function Carrito(props) {
  const {
    carrito,
    agregarCarrito,
    eliminarCarrito,
    influencers,
    seteos,
    cliente,
    autoCarga,
    loguearUsuario,
    mandarMensajes,
    dameMiCurso,
    cursoscomprados,
  } = useContext(Contexto);
  const location = useLocation();
  const [totalPesos, setTotalPesos] = useState(0);
  const [totalDolar, setTotalDolar] = useState(0);
  const [totalBruto, setTotalBruto] = useState(0);
  const [descuentos, setDescuentos] = useState(0);
  const [astrohack, setAstrohack] = useState(105000);
  const [armadoProductos, setArmadoProductos] = useState(0);
  const [imgProd, setImgProd] = useState("");
  const [regalado, setRegalado] = useState("");
  console.log("location.hash", location);
  const [palabraClave, setPalabraClave] = useState(0);
  /*eslint-disable */
  let totalAntesDescuento = 0;
  /*eslint-disable */
  let isOferta = false;
  let total = 0;
  let ahorro = 0;

  //Si no hay nada en carrito, me rajo al home
  const navigate = useNavigate();

  useEffect(() => {}, [totalDolar]);
  useEffect(() => {
    //calcularDescuento();
    console.log(cursoscomprados);
  }, [cursoscomprados]);
  // const calcularDescuento = () => {
  //   console.log("llama a calcular descuento genteSummer");
  //   if (
  //     cursoscomprados.summerhack23 ||
  //     cursoscomprados.summerhack2022 ||
  //     cursoscomprados.summerhack24 ||
  //     cursoscomprados.summerhack2021 ||
  //     cursoscomprados.summerhack2021feb ||
  //     cursoscomprados.winterhack ||
  //     cursoscomprados.winterhack23 ||
  //     cursoscomprados.winterhack24 ||
  //     cursoscomprados.easterhack
  //   ) {
  //     setPrecioFinal(80000);
  //     setDescuentardo(70000);
  //     console.log("LE descontamos perri");
  //     setMensaje(
  //       " Estás comprando con el descuento deEsrlyBird 30%OFF y, ¡wow! <br> Te restamos un 20% más por ser exalumnx del programa. "
  //     );
  //   } else {
  //     setPrecioFinal(105000);
  //     setDescuentardo(45000);
  //   }
  // };

  //Hacemos las cuentas...
  useEffect(() => {
    if (seteos) {
      carrito.map((item, i) => {
        calcularPrecio(
          parseInt(item[0].precio),
          parseInt(item[0].descuento),
          item[0].oferta
        );
      });
    }

    if (carrito.length === 0) {
      setTotalBruto(0);
      setDescuentos(0);
      setTotalPesos(0);
      //navigate("/");
    }
  }, [carrito, seteos]);

  useEffect(() => {
    if (location.hash) agregarCarrito(location.hash.substring(1));
  }, [seteos]);
  const handleChange = (event) => {
    setPalabraClave(event.target.value);
  };
  const descontame = () => {
    let excepcion = false;

    if (influencers[palabraClave]) {
      if (influencers[palabraClave].excepcion != false) {
        for (let item in carrito) {
          if (
            carrito[item][0].url.substr(1) ==
            influencers[palabraClave].excepcion
          ) {
            excepcion = true;
          }
          console.log(
            carrito[item][0].url.substr(1),
            influencers[palabraClave].excepcion
          );
        }

        if (excepcion) {
          console.log(
            "execepcion:",
            influencers[palabraClave].leyendaExcepcion
          );
          mandarMensajes(influencers[palabraClave].leyendaExcepcion);
        } else {
          calcularPrecio(
            totalBruto,
            parseInt(influencers[palabraClave].descuento),
            "ofertuli"
          );
          mandarMensajes(influencers[palabraClave].leyenda);
        }
      } else {
        // si no tengo excepciones
        if (influencers[palabraClave].condicion != "no") {
          if (cursoscomprados[influencers[palabraClave].condicion]) {
            validarCursoDescuento();
          } else {
            mandarMensajes(influencers[palabraClave].leyendaRobo);
          }
        } else {
          validarCursoDescuento();
        }
      }

      //termina validaciond se codifo
    } else {
      mandarMensajes("No existe ese codiguitou");
    }
  };
  let validarCursoDescuento = () => {
    if (influencers[palabraClave].curso != "all") {
      if (
        carrito[0][0].url.substr(1) == influencers[palabraClave].curso &&
        carrito.length == 1
      ) {
        calcularPrecio(
          totalBruto,
          parseInt(influencers[palabraClave].descuento),
          "ofertuli"
        );
        mandarMensajes(influencers[palabraClave].leyenda);
      } else {
        //si no tengo solo el curso de la condicion
        mandarMensajes(influencers[palabraClave].instruccion);
      }
    } else {
      mandarMensajes(influencers[palabraClave].leyenda);

      calcularPrecio(
        totalBruto,
        parseInt(influencers[palabraClave].descuento),
        "ofertuli"
      );
    }
  };

  const calcularPrecio = (pr, des, of) => {
    let precio = pr;
    let descuento = des;
    if (of == "no") {
      total = total + precio;
    } else {
      isOferta = true;
      let desc = 100 - descuento;
      let totalx = precio;

      totalx = (totalx * desc) / 100;
      total = total + totalx;
      ahorro = ahorro + (precio * descuento) / 100;
    }
    totalAntesDescuento = totalAntesDescuento + precio;
    setTotalBruto(totalAntesDescuento);
    setDescuentos(ahorro);
    setTotalPesos(total);
    let totalito = Math.round(total / 1214);
    if (totalito < 1) {
      totalito = 1;
    }

    setTotalDolar(0);
    setTimeout(() => {
      setTotalDolar(totalito.toFixed(2));
    }, 100);
    armadoPaPaypal();
  };
  const handleCheck = (e) => {
    setRegalado(e.target.checked);
    if (e.target.checked) {
      mandarMensajes("Que bien vas a regalar esta compra.");
    } else {
      mandarMensajes("Uy, te arrepentiste... bueh");
    }
  };

  let armadoPaPaypal = () => {
    let cursosOk = [];
    for (const prop in carrito) {
      let nombre = "";
      if (!carrito[prop][0].paquete) {
        cursosOk.push(carrito[prop][0].url.substr(1));
      } else {
        for (const pro in carrito[prop][0].productos) {
          let hijo = carrito[prop][0].productos[pro].url.substr(1);
          cursosOk.push(hijo);
        }
      }
    }

    setArmadoProductos(cursosOk);
  };
  let dameCursos = () => {
    dameMiCurso({ pibe: cliente.uid, cursin: armadoProductos });
    mandarMensajes(
      `Gracias por tu compra! te agregué ${armadoProductos}, podés verlos en tus cursos ya mismo.`
    );
  };
  return (
    <>
      <div className="contenedor">
        <div className="contenedor-carrito">
          {carrito.length == 0 && location.hash == "" && <Navigate to="/" />}
          {carrito &&
            carrito.map((item, i) => {
              if (item[0].paquete) {
                return (
                  <CarritoPaquete
                    key={`CarritoPaquete${i}`}
                    {...item[0]}
                    eliminar={eliminarCarrito}
                  />
                );
              } else {
                return (
                  <>
                    <CarritoItem
                      key={`CarritoItem${i}`}
                      {...item[0]}
                      eliminar={eliminarCarrito}
                    />
                  </>
                );
              }
            })}

          <p className="carrito-bruto">Bruto: AR$ {totalBruto}</p>
          <p className="carritoAhorro">Te ahorras: AR$ {descuentos}</p>
          <p className="carritoTotal">Total: AR$ {totalPesos}</p>
          <p className="carritoDolares">En dolares: U$D {totalDolar}</p>
          {/* <button onClick={dameCursos}>DAMELOS AHORA</button> */}
          {/* <p className="carrito-bruto">
            X Transferencia -20%: AR$ {(totalPesos / 100) * 80}
          </p>
         <p className="transferencia2">
            Con cada curso que compres durante <br />
            los días de Ofertosa, <br />
            <strong>¡te regalamos otro a elección!</strong> <br /> Una vez que
            hayas hecho la compra, enviá tu comprobante de pago a
            rana@codearock.com y decinos qué curso querés que te regalemos y con
            qué mail te logueás en la web. <br /> Esta promo es válida para
            todos los cursos individuales anuales.
          </p> 
          <p className="transferencia">
            Transferencia al alias: <strong>codearock</strong> <br></br>a nombre
            de: <strong>Jonatan Ariste Ojeda</strong> <br /> Enviá el
            comprobante a rana@codearock.com <br />
            para que habilitemos tu curso.
          </p>
*/}
          <div className="dataCompra">
            {cliente ? (
              <>
                <div className="regalito">
                  <img className="regaloimg" src={Regalo} alt="" />
                  <label htmlFor="regalo">Quiero regalar esta compra.</label>
                  <br />
                  <input
                    type="checkbox"
                    id="regala"
                    onChange={handleCheck}
                    checked={regalado}
                  ></input>
                </div>
                <MercadoPago
                  precio={totalPesos}
                  nombre={cliente.nombre}
                  username={cliente.username}
                  img_prod={imgProd}
                  uid={cliente.uid}
                  email={cliente.email}
                  carrito={carrito}
                  regalito={regalado}
                ></MercadoPago>
                {totalDolar > 0 && (
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "AeQoTBWx-TzhTl4IYcqymIHDKtAsQMShHuFzGypcApY2GdbaCCw6nEsMtOS40Be9ti1I0p2qXmtSu8sE",
                    }}
                  >
                    <h1 className="pagosOtros">Pagos desde otros países</h1>
                    <PayPalButtons
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                currency_code: "USD",
                                value: totalDolar,
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          const name = details.payer.name.given_name;
                          mandarMensajes(`Transaction completed by ${name}`);
                          dameCursos();
                        });
                      }}
                      style={{ tagline: false }}
                    />
                  </PayPalScriptProvider>
                )}
              </>
            ) : (
              <>
                <br />
                <br />
                <button
                  className="btn-loco"
                  onClick={() => {
                    loguearUsuario();
                  }}
                >
                  {" "}
                  Logueate para sumarte
                </button>
                <br /> <br />
                <br />
                <hr />
              </>
            )}

            <div className="descuentardos">
              <input type="text" onChange={handleChange} /> <br />
              <button onClick={descontame} className="btn-loco">
                Tengo un descuento
              </button>
            </div>
          </div>
        </div>
        <div className="clorcito off">
          <img src={Piza} alt="" className="piz" />
        </div>
      </div>
    </>
  );
}

export default Carrito;
