import lechita from "../assets/statics/lechita.png";
import React from "react";
import Contexto from "../context/Contexto";
import { useContext } from "react";
import "../assets/css/Examenes.css";

function ExamenesBien(props) {
  const { bajarCertificado, curso } = props;
  const { cliente, guardarDatos, usuario } = useContext(Contexto);

  return (
    <>
      <div className="contenedorExamenes">
        <h1>¡¡VAMAAAAA, APROBASTE!! </h1>

        <img src={lechita} alt="" className="esperar-imagen" />

        <h3>
          ¡Superaste el exameen, felicitacionesss! Ya podés descargar el
          certificado y sumarlo a tu LinkedIn.
        </h3>
        <button
          onClick={() => {
            bajarCertificado(curso);
          }}
          className="btn-loco"
        >
          {" "}
          BAJAR MI CERTIFICADO
        </button>
      </div>
    </>
  );
}

export default ExamenesBien;
