import React from "react";
function P404() {
  return (
    <>
      <h1>404 pa</h1>
    </>
  );
}

export default P404;
