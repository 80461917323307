import { useContext, useState } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Entrar.css";
import Google from "../assets/statics/google.png";

const Crear = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const {
    loguearUsuario,
    loginUsuarioConEmail,
    mandarMensajes,
    resetPassword,
    estamosEntrando,
    crearUsuarioConEmail,
    estamosCreando,
  } = useContext(Contexto);
  const conEmail = () => {
    if (email == "" && pass == "") {
      mandarMensajes("Completa porfi Usuario y Password");
    } else if (email == "") {
      mandarMensajes("Completa el campo email porfi");
    } else if (pass == "") {
      mandarMensajes("Te faltó el pass ;)");
    } else {
      crearUsuarioConEmail(email, pass);
      mandarMensajes("Estoy creando tu cuenta");
      estamosCreando(false);
    }
    // loginUsuarioConEmail(usuario, pass);
  };
  const handlechange = (e) => {
    if (e.target.name == "email") setEmail(e.target.value);
    if (e.target.name == "pass") setPass(e.target.value);
  };
  const recuperar = () => {
    if (email == "") {
      mandarMensajes(
        "Completa el campo email porfi para recuperar tu password"
      );
    } else {
      resetPassword();
      mandarMensajes("Te mande un link a tu correo :)");
    }
  };
  const cerrar = () => {
    estamosCreando(false);
  };

  const entrar = () => {
    estamosEntrando(true);
  };
  return (
    <>
      <div className="loader-wraper2">
        <button className="cerrarx" onClick={cerrar}>
          X
        </button>
        <button className="Entrarcongoogle" onClick={loguearUsuario}>
          <img src={Google} alt="" /> Crear cuenta con google
        </button>
        <br />
        <p>O crear cuenta con tu email</p>
        <div>
          <input
            type="text"
            className="entrada"
            onChange={handlechange}
            placeholder="Email"
            name="email"
          />
          <br /> <br />
          <input
            type="password"
            className="entrada"
            placeholder="Contraseña"
            onChange={handlechange}
            name="pass"
          />
          <br />
          <br />
          <button className="Entra" onClick={conEmail}>
            Crear cuenta
          </button>
          <br />
          <br /> <br /> <br /> <br />
          ¿Ya tenés cuenta?
          <button className="olvidastebtn">
            {" "}
            <strong onClick={entrar}>Entrar con tu cuenta</strong>{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Crear;
