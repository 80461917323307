function Privacidad() {
  return (
    <>
      <div>
        <h1>Política de Privacidad</h1>
        <p>Actualizado el 2023-06-13</p>
        <p>CodeaRock.</p>
        <p>
          <span>
            ("nosotros", "nuestro" o "nos") se compromete a proteger su
            privacidad. Esta Política de privacidad explica cómo
          </span>
        </p>
        <p>CodeaRock.</p>
        <p>
          <span>recopila, usa y divulga su información personal.</span>
        </p>
        <br />
        <div>
          <p>
            <span>
              Esta Política de privacidad se aplica a nuestro sitio web,
            </span>
          </p>
          <p>
            <span> https://codearock.com/</span>
          </p>
          <p>
            <span>
              , y sus subdominios asociados (colectivamente, nuestro "Servicio")
            </span>
          </p>
        </div>
        <p>
          <span>
            Al acceder o utilizar nuestro Servicio, usted indica que ha leído,
            comprendido y aceptado nuestra recopilación, almacenamiento, uso y
            divulgación de su información personal como se describe en esta
            Política de privacidad y en nuestros Términos de servicio.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Definiciones y Términos Clave</h1>
        <p>
          <span>
            Para ayudar a explicar las cosas de la manera más clara posible en
            esta Política de privacidad, cada vez que se hace referencia a
            cualquiera de estos términos, se definen estrictamente como:
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul>
          <li>
            <span>
              <strong>Cookie:</strong> pequeña cantidad de datos generados por
              un sitio web y guardados por su navegador web. Se utiliza para
              identificar su navegador, proporcionar análisis, recordar
              información sobre usted, como su preferencia de idioma o
              información de inicio de sesión.
              <br />
            </span>
          </li>

          <li>
            <span>
              <strong>Compañía:</strong> cuando esta política menciona
              "Compañía", "nosotros", "nos" o "nuestro", se refiere a
              <a>CodeaRock</a> que es responsable de su información en virtud de
              esta Política de privacidad.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Plataforma:</strong> sitio web de Internet, aplicación web
              o aplicación digital de cara al público de <a>CodeaRock</a>
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>País:</strong> donde se encuentra <a>CodeaRock</a> o los
              propietarios / fundadores de <a>CodeaRock</a> en este caso es
              <a>Argentina</a>.<br />
            </span>
          </li>
          <li>
            <span>
              <strong>Cliente:</strong> se refiere a la empresa, organización o
              persona que se registra para utilizar el Servicio
              <a>CodeaRock</a> para gestionar las relaciones con sus
              consumidores o usuarios del servicio.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Dispositivo:</strong> cualquier dispositivo conectado a
              Internet, como un teléfono, tablet, computadora o cualquier otro
              dispositivo que se pueda usar para visitar <a>CodeaRock</a> y usar
              los servicios.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Dirección IP:</strong> a cada dispositivo conectado a
              Internet se le asigna un número conocido como dirección de
              protocolo de Internet (IP). Estos números generalmente se asignan
              en bloques geográficos. A menudo, se puede utilizar una dirección
              IP para identificar la ubicación desde la que un dispositivo se
              conecta a Internet.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Personal:</strong> se refiere a aquellas personas que son
              empleadas por <a>CodeaRock</a> o están bajo contrato para realizar
              un servicio en nombre de una de las partes.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Datos personales:</strong> cualquier información que
              directa, indirectamente o en conexión con otra información,
              incluido un número de identificación personal, permita la
              identificación de una persona física.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Servicio:</strong> se refiere al servicio brindado por
              <a>CodeaRock</a> como se describe en los términos relativos (si
              están disponibles) y en esta plataforma.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Terceros:</strong> se refiere a anunciantes,
              patrocinadores de concursos, socios promocionales y de marketing,
              y otros que brindan nuestro contenido o cuyos productos o
              servicios que creemos que pueden interesarle.
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Sitio web:</strong> el sitio de <a>CodeaRock</a>, al que
              se puede acceder a través de esta URL:{" "}
              <a>https://codearock.com/.</a>
              <br />
            </span>
          </li>
          <li>
            <span>
              <strong>Usted:</strong> una persona o entidad que está registrada
              con <a>CodeaRock</a> para utilizar los Servicios.
              <br />
            </span>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <div>
          <h1>¿Qué información recopilamos?</h1>
          <p>
            <span>
              Recopilamos información suya cuando visita nuestra plataforma, se
              registra en nuestro sitio, realiza un pedido, se suscribe a
              nuestro boletín, responde a una encuesta o completa un formulario.
            </span>
          </p>
          <ul>
            <li>
              <span>Nombre / nombre de usuario</span>
            </li>

            <li>
              <span>Correos electrónicos</span>
            </li>
            <li>
              <span>Direcciones de correo</span>
            </li>

            <li>
              <span>Contraseña</span>
            </li>
          </ul>
        </div>
        <p className="p12">
          <br />
        </p>
        <p>
          <span>
            También recopilamos información de dispositivos móviles para una
            mejor experiencia de usuario, aunque estas funciones son
            completamente opcionales:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Cámara (imágenes): otorgar <strong>permiso a la cámara</strong> le
              permite al usuario cargar cualquier imagen directamente desde la
              plataforma, puede denegar de manera segura los permisos de la
              cámara para esta plataforma.
            </span>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <h1>¿Cómo usamos la información que recopilamos?</h1>
        <p>
          <span>
            Cualquiera de la información que recopilamos de usted puede usarse
            de una de las siguientes maneras:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Para personalizar su experiencia (su información nos ayuda a
              responder mejor a sus necesidades individuales)
            </span>
          </li>
          <li>
            <span>
              Para mejorar nuestra plataforma (nos esforzamos continuamente por
              mejorar lo que ofrece nuestra plataforma en función de la
              información y los comentarios que recibimos de usted)
            </span>
          </li>
          <li>
            <span>
              Para mejorar el servicio al cliente (su información nos ayuda a
              responder de manera más efectiva a sus solicitudes de servicio al
              cliente y necesidades de soporte)
            </span>
          </li>
          <li>
            <span>Para procesar transacciones</span>
          </li>
          <li>
            <span>
              Para administrar un concurso, promoción, encuesta u otra
              característica del sitio
            </span>
          </li>
          <li>
            <span>Para enviar correos electrónicos periódicos</span>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <h1>
          ¿Cuándo usa <a>CodeaRock</a> la información del cliente de terceros?
        </h1>
        <p>
          <span>
            Recibimos información de terceros cuando se comunica con nosotros.
            Por ejemplo, cuando nos envía su dirección de correo electrónico
            para mostrar interés en convertirse en cliente de <a>CodeaRock</a>,
            recibimos información de un tercero que brinda servicios automáticos
            de detección de fraude a <a>CodeaRock</a>. Ocasionalmente, también
            recopilamos información que se pone a disposición del público en los
            sitios web de redes sociales. Puede controlar la cantidad de
            información que los sitios web de redes sociales hacen pública
            visitando estos sitios web y cambiando su configuración de
            privacidad.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>¿Compartimos la información que recopilamos con terceros?</h1>
        Podemos compartir la información que recopilamos, tanto personal como no
        personal, con terceros como anunciantes, patrocinadores de concursos,
        socios promocionales y de marketing, y otros que proporcionan nuestro
        contenido o cuyos productos o servicios creemos que pueden interesarle.
        También podemos compartirlo con nuestras compañías afiliadas y socios
        comerciales actuales y futuros, y si estamos involucrados en una fusión,
        venta de activos u otra reorganización comercial, también podemos
        compartir o transferir su información personal y no personal a nuestros
        sucesores en interés. <br />
        <br />
        Podemos contratar proveedores de servicios de terceros de confianza para
        que realicen funciones y nos brinden servicios, como el alojamiento y el
        mantenimiento de nuestros servidores y la plataforma, almacenamiento y
        administración de bases de datos, administración de correo electrónico,
        marketing de almacenamiento, procesamiento de tarjetas de crédito,
        servicio y cumplimiento de pedidos de productos y servicios que puede
        comprar a través de la plataforma. Es probable que compartamos su
        información personal, y posiblemente alguna información no personal, con
        estos terceros para permitirles realizar estos servicios para nosotros y
        para usted.&nbsp; <br />
        <br />
        Podemos compartir partes de los datos de nuestro archivo de registro,
        incluidas las direcciones IP, con fines analíticos con terceros, como
        socios de análisis web, desarrolladores de aplicaciones y redes
        publicitarias. Si se comparte su dirección IP, se puede utilizar para
        estimar la ubicación general y otros datos tecnológicos, como la
        velocidad de conexión, si ha visitado la plataforma en una ubicación
        compartida y el tipo de dispositivo utilizado para visitar la
        plataforma. Pueden agregar información sobre nuestra publicidad y lo que
        ve en la plataforma y luego proporcionar auditorías, investigaciones e
        informes para nosotros y nuestros anunciantes.&nbsp; <br />
        <br />
        También podemos divulgar información personal y no personal sobre usted
        al gobierno, a funcionarios encargados de hacer cumplir la ley o a
        terceros privados, según consideremos, a nuestro exclusivo criterio,
        necesario o apropiado para responder a reclamos, procesos legales
        (incluidas citaciones), para proteger nuestra derechos e intereses o los
        de un tercero, la seguridad del público o de cualquier persona, para
        prevenir o detener cualquier actividad ilegal, poco ética o legalmente
        procesable, o para cumplir con las órdenes judiciales, leyes, reglas y
        regulaciones aplicables.&nbsp;
        <p></p>
        <p>
          <br />
        </p>
        <h1>
          ¿Dónde y cuándo se recopila la información de los clientes y usuarios
          finales?
        </h1>
        <p>
          <span>
            <a>CodeaRock</a> recopilará la información personal que nos envíe.
            También podemos recibir información personal sobre usted de terceros
            como se describe anteriormente.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <h1>¿Podría transferirse mi información a otros países?</h1>
        <p>
          <span>
            Estamos incorporados en Argentina. La información recopilada a
            través de nuestro sitio web, a través de interacciones directas con
            usted o del uso de nuestros servicios de ayuda puede transferirse de
            vez en cuando a nuestras oficinas o personal, o a terceros, ubicados
            en todo el mundo, y puede verse y alojarse en cualquier lugar de el
            mundo, incluidos los países que pueden no tener leyes de aplicación
            general que regulen el uso y la transferencia de dichos datos. En la
            mayor medida permitida por la ley aplicable, al utilizar cualquiera
            de los anteriores, usted acepta voluntariamente la transferencia
            transfronteriza y el alojamiento de dicha información.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>
          ¿La información recopilada a través del Servicio <a>CodeaRock</a> es
          segura?
        </h1>
        <p>
          <span>
            Tomamos precauciones para proteger la seguridad de su información.
            Contamos con procedimientos físicos, electrónicos y administrativos
            para ayudar a salvaguardar, prevenir el acceso no autorizado,
            mantener la seguridad de los datos y usar correctamente su
            información. Sin embargo, ni las personas ni los sistemas de
            seguridad son infalibles, incluidos los sistemas de cifrado. Además,
            las personas pueden cometer delitos intencionales, cometer errores o
            no seguir las políticas. Por lo tanto, aunque hacemos todos los
            esfuerzos razonables para proteger su información personal, no
            podemos garantizar su seguridad absoluta. Si la ley aplicable impone
            algún deber irrenunciable de proteger su información personal, usted
            acepta que la mala conducta intencional serán los estándares
            utilizados para medir nuestro cumplimiento con ese deber.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>¿Puedo actualizar o corregir mi información?</h1>
        <p>
          <span>
            Los derechos que tienes para solicitar actualizaciones o
            correcciones de la información que recopila <a>CodeaRock</a>{" "}
            dependen de tu relación con <a>CodeaRock</a>. El personal puede
            actualizar o corregir su información según se detalla en nuestras
            políticas de empleo internas de la empresa. <br />
            <br />
            Los clientes tienen derecho a solicitar la restricción de ciertos
            usos y divulgaciones de información de identificación personal de la
            siguiente manera. Puede comunicarse con nosotros para (1) actualizar
            o corregir su información de identificación personal, (2) cambiar
            sus preferencias con respecto a las comunicaciones y otra
            información que recibe de nosotros, o (3) eliminar la información de
            identificación personal que se mantiene sobre usted en nuestro
            sistema (sujeto al siguiente párrafo), cancelando su cuenta. Dichas
            actualizaciones, correcciones, cambios y eliminaciones no tendrán
            ningún efecto sobre otra información que mantenemos o información
            que hayamos proporcionado a terceros de acuerdo con esta Política de
            privacidad antes de dicha actualización, corrección, cambio o
            eliminación. Para proteger su privacidad y seguridad, podemos tomar
            medidas razonables (como solicitar una contraseña única) para
            verificar su identidad antes de otorgarle acceso a su perfil o hacer
            correcciones. Usted es responsable de mantener en secreto su
            contraseña única y la información de su cuenta en todo momento.
            <br />
            <br />
            Debe tener en cuenta que tecnológicamente no es posible eliminar
            todos y cada uno de los registros de la información que nos ha
            proporcionado de nuestro sistema. La necesidad de realizar copias de
            seguridad de nuestros sistemas para proteger la información de
            pérdidas involuntarias significa que puede existir una copia de su
            información en una forma que no se pueda borrar y que será difícil o
            imposible de localizar para nosotros. Inmediatamente después de
            recibir su solicitud, toda la información personal almacenada en las
            bases de datos que usamos activamente y otros medios fácilmente
            buscables se actualizará, corregirá, cambiará o eliminará, según
            corresponda, tan pronto como y en la medida en que sea razonable y
            técnicamente posible.
            <br />
            <br />
            Si es un usuario final y desea actualizar, eliminar o recibir
            cualquier información que tengamos sobre usted, puede hacerlo
            poniéndose en contacto con la organización de la que es cliente.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Venta de Negocio</h1>
        <p>
          <span>
            Nos reservamos el derecho de transferir información a un tercero en
            el caso de una venta, fusión u otra transferencia de todos o
            sustancialmente todos los activos de <a>CodeaRock</a> o cualquiera
            de sus afiliadas corporativas (como se define en este documento), o
            la porción de <a>CodeaRock</a> o cualquiera de sus Afiliadas
            corporativas con las que se relaciona el Servicio, o en el caso de
            que discontinuemos nuestro negocio o presentemos una petición o
            hayamos presentado una petición contra nosotros en caso de quiebra,
            reorganización o procedimiento similar, siempre que el el tercero
            acepte adherirse a los términos de esta Política de privacidad.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Afiliados</h1>
        <p>
          <span>
            Podemos divulgar información (incluida la información personal)
            sobre usted a nuestros afiliados corporativos. Para los propósitos
            de esta Política de Privacidad, "Afiliado Corporativo" significa
            cualquier persona o entidad que directa o indirectamente controla,
            está controlada por o está bajo control común con <a>CodeaRock</a>,
            ya sea por propiedad o de otra manera. Cualquier información
            relacionada con usted que proporcionemos a nuestros afiliados
            corporativos será tratada por dichos afiliados corporativos de
            acuerdo con los términos de esta política de privacidad.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>¿Cuánto tiempo conservamos su información?</h1>
        <p>
          <span>
            Conservamos su información solo mientras la necesitemos para
            proporcionarle <a>CodeaRock</a> y cumplir con los propósitos
            descritos en esta política. Este también es el caso de cualquier
            persona con la que compartamos su información y que lleve a cabo
            servicios en nuestro nombre. Cuando ya no necesitemos usar su
            información y no sea necesario que la conservemos para cumplir con
            nuestras obligaciones legales o reglamentarias, la eliminaremos de
            nuestros sistemas o la despersonalizaremos para que no podamos
            identificarlo.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>¿Cómo protegemos su información?</h1>
        <p>
          <span>
            Implementamos una variedad de medidas de seguridad para mantener la
            seguridad de su información personal cuando realiza un pedido,
            ingresa, envía o accede a su información personal. Ofrecemos el uso
            de un servidor seguro. Toda la información confidencial / crediticia
            suministrada se transmite a través de la tecnología Secure Socket
            Layer (SSL) y luego se encripta en nuestra base de datos de
            proveedores de pasarela de pago solo para que sea accesible por
            aquellos autorizados con derechos especiales de acceso a dichos
            sistemas, y deben mantener la información confidencial. Después de
            una transacción, su información privada (tarjetas de crédito,
            números de seguro social, finanzas, etc.) nunca se archiva. Sin
            embargo, no podemos garantizar la seguridad absoluta de la
            información que transmita a <a>CodeaRock</a> ni garantizar que su
            información en el servicio no sea accedida, divulgada, alterada o
            destruida por una infracción de cualquiera de nuestras condiciones
            físicas, salvaguardias técnicas o de gestión.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Ley que Rige</h1>
        <p>
          <span>
            Esta Política de privacidad se rige por las leyes de Argentina sin
            tener en cuenta su disposición sobre conflicto de leyes. Usted
            acepta la jurisdicción exclusiva de los tribunales en relación con
            cualquier acción o disputa que surja entre las partes en virtud de
            esta Política de privacidad o en relación con ella, excepto aquellas
            personas que puedan tener derecho a presentar reclamaciones en
            virtud del Escudo de privacidad o el marco suizo-estadounidense.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Tu consentimiento</h1>
        <p>
          <span>
            Hemos actualizado nuestra Política de privacidad para brindarle
            total transparencia sobre lo que se establece cuando visita nuestro
            sitio y cómo se utiliza. Al utilizar nuestra plataforma, registrar
            una cuenta o realizar una compra, por la presente acepta nuestra
            Política de privacidad y acepta sus términos.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Enlaces a otros Sitios Web</h1>
        <p>
          <span>
            Esta Política de privacidad se aplica solo a los Servicios. Los
            Servicios pueden contener enlaces a otros sitios web que
            <a>CodeaRock</a> no opera ni controla. No somos responsables por el
            contenido, la precisión o las opiniones expresadas en dichos sitios
            web, y dichos sitios web no son investigados, monitoreados o
            verificados por nuestra precisión o integridad. Recuerde que cuando
            utiliza un enlace para ir desde los Servicios a otro sitio web,
            nuestra Política de privacidad deja de estar en vigor. Su navegación
            e interacción en cualquier otro sitio web, incluidos aquellos que
            tienen un enlace en nuestra plataforma, están sujetos a las propias
            reglas y políticas de ese sitio web. Dichos terceros pueden utilizar
            sus propias cookies u otros métodos para recopilar información sobre
            usted.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Cookies</h1>
        <p>
          <span>
            <a>CodeaRock</a> utiliza "cookies" para identificar las áreas de
            nuestro sitio web que ha visitado. Una cookie es una pequeña porción
            de datos que su navegador web almacena en su computadora o
            dispositivo móvil. Usamos cookies para mejorar el rendimiento y la
            funcionalidad de nuestra plataforma, pero no son esenciales para su
            uso. Sin embargo, sin estas cookies, es posible que ciertas
            funciones, como los videos, no estén disponibles o se le solicitará
            que ingrese sus datos de inicio de sesión cada vez que visite la
            plataforma, ya que no podríamos recordar que había iniciado sesión
            anteriormente. La mayoría de los navegadores web se pueden
            configurar para desactivar el uso de cookies. Sin embargo, si
            desactiva las cookies, es posible que no pueda acceder a la
            funcionalidad de nuestro sitio web correctamente o en absoluto.
            Nunca colocamos información de identificación personal en cookies.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p className="p15">
          <br />
        </p>
        <h1>Detalles del Pago</h1>
        <p>
          <span>
            Con respecto a cualquier tarjeta de crédito u otros detalles de
            procesamiento de pagos que nos haya proporcionado, nos comprometemos
            a que esta información confidencial se almacene de la manera más
            segura posible.
          </span>
        </p>
        <p className="p15">
          <br />
        </p>
        <h1>Privacidad de los Niños</h1>
        <p>
          <span>
            Recopilamos información de niños menores de 13 años solo para
            mejorar nuestros servicios. Si usted es padre o tutor y sabe que su
            hijo nos ha proporcionado datos personales sin su permiso,
            comuníquese con nosotros. Si nos damos cuenta de que hemos
            recopilado datos personales de cualquier persona menor de 13 años
            sin la verificación del consentimiento de los padres, tomamos
            medidas para eliminar esa información de nuestros servidores.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Cambios en nuestra Política de Privacidad</h1>
        <p>
          <span>
            Podemos cambiar nuestro Servicio y nuestras políticas, y es posible
            que debamos realizar cambios en esta Política de privacidad para que
            reflejen con precisión nuestro Servicio y nuestras políticas. A
            menos que la ley exija lo contrario, le notificaremos (por ejemplo,
            a través de nuestro Servicio) antes de realizar cambios en esta
            Política de privacidad y le daremos la oportunidad de revisarlos
            antes de que entren en vigencia. Luego, si continúa utilizando el
            Servicio, estará sujeto a la Política de privacidad actualizada. Si
            no desea aceptar esta o cualquier Política de privacidad
            actualizada, puede eliminar su cuenta.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Servicios de terceros</h1>
        <p>
          <span>
            Podemos mostrar, incluir o poner a disposición contenido de terceros
            (incluidos datos, información, aplicaciones y otros servicios de
            productos) o proporcionar enlaces a sitios web o servicios de
            terceros ("Servicios de terceros").
          </span>
        </p>
        <p>
          <span>
            Usted reconoce y acepta que <a>CodeaRock</a> no será responsable de
            ningún Servicio de terceros, incluida su precisión, integridad,
            puntualidad, validez, cumplimiento de los derechos de autor,
            legalidad, decencia, calidad o cualquier otro aspecto de los mismos.
            <a>CodeaRock</a> no asume ni tendrá ninguna obligación o
            responsabilidad ante usted o cualquier otra persona o entidad por
            los Servicios de terceros.
          </span>
        </p>
        <p>
          <span>
            Los Servicios de terceros y los enlaces a los mismos se brindan
            únicamente para su conveniencia y usted accede a ellos y los usa
            completamente bajo su propio riesgo y sujeto a los términos y
            condiciones de dichos terceros.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h1>Facebook Pixel</h1>
        <p>
          <span>
            Facebook pixel&nbsp;es una herramienta de análisis que le permite
            medir la efectividad de su publicidad al comprender las acciones que
            las personas realizan en su sitio web. Puede utilizar el píxel para:
            Asegurarse de que sus anuncios se muestren a las personas adecuadas.
            Facebook pixel puede recopilar información de su dispositivo cuando
            utiliza el servicio. Facebook pixel recopila información que se
            guarda de acuerdo con su Política de privacidad.
          </span>
        </p>
      </div>
    </>
  );
}

export default Privacidad;
