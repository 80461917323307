import React from "react";
import ExamenesEsperar from "./ExamenesEsperar";
import ExamenesPrevia from "./ExamenesPrevia";
function ExamenesWinter(props) {
  const { okWinter, guardarYArrancar, cuantoFalta, vioTodo, laUrl } = props;
  return okWinter ? (
    <ExamenesPrevia
      guardarYArrancar={guardarYArrancar}
      okWinter={okWinter}
    ></ExamenesPrevia>
  ) : (
    <ExamenesEsperar
      cuantoFalta={cuantoFalta}
      vioTodo={vioTodo}
      okWinter={okWinter}
      laUrl={laUrl}
    ></ExamenesEsperar>
  );
}

export default ExamenesWinter;
