import React from "react";

function ExamenesPregunta(props) {
  const { pregunta, respuestas, setPuntage, posicion, img } = props;
  return (
    <>
      <p>
        <strong> {pregunta}</strong>
      </p>
      {img && <img src={img} alt="" className="imgPregunta" />}

      <form>
        {respuestas &&
          respuestas.map((item, i) => (
            <>
              <input
                type="radio"
                id={item.res.replace(/\s+/g, "")}
                name="fav_language"
                value={`${Date.now()}${item.valor}`}
                onClick={() => {
                  setPuntage(posicion, item.valor, pregunta, item.res);
                }}
              />
                <label for={item.res.replace(/\s+/g, "")}>{item.res}</label>
              <br />
            </>
          ))}
      </form>
    </>
  );
}

export default ExamenesPregunta;
