import React from "react";
import LogoMercadoPago from "../assets/statics/mercadopago.svg";
import Tarjetas from "../assets/statics/tarjetas.png";
const MercadoPagoLite = (props) => {
  const { precio, uid, email, producto, username, img_prod } = props;

  return (
    <>
      <form
        className="formularioPAgo"
        name="formulario"
        method="post"
        action="https://codeapagos.vercel.app/pagos"
      >
        <input name="idProducto" value={producto} type="hidden" />
        <input name="producto" value={producto} type="hidden" />
        <input name="precio" value={precio} type="hidden" />
        <input name="imagen" value={img_prod} type="hidden" />
        <input name="nombre" value={username} type="hidden" />
        <input name="uid" value={uid} type="hidden" />
        <input name="mail" value={email} type="hidden" />

        <button type="submit" value="COMPRAR" className="btn btn-success">
          <img
            src={LogoMercadoPago}
            alt="Mercado Pago"
            className="mercadopagobtn"
          />
        </button>
        <img src={Tarjetas} alt="tarjetas" className="tarjetas" />
      </form>
    </>
  );
};
export default MercadoPagoLite;
