import React from "react";
import { useEffect, useState } from "react";
import "../assets/css/App.css";
function Timer(props) {
  const { pararExamen, pararContador, tiempo } = props;
  const [diff, setDiff] = useState(null);
  const [initial, setInitial] = useState(null);
  const tick = () => {
    setDiff(new Date(+new Date() - initial));
  };
  const start = () => {
    setInitial(+new Date());
  };
  useEffect(() => {
    start();
    if (initial) {
      requestAnimationFrame(tick);
    }
  }, [initial]);
  useEffect(() => {
    if (diff) {
      requestAnimationFrame(tick);
    }
  }, [diff]);
  const timeFormat = (date) => {
    if (!date) return "00:00:00";
    let mm = date.getUTCMinutes();
    let ss = date.getSeconds();
    let cm = Math.round(date.getMilliseconds() / 10);
    mm = mm < 10 ? "0" + mm : mm;
    ss = ss < 10 ? "0" + ss : ss;
    cm = cm < 10 ? "0" + cm : cm;
    if (mm <= tiempo) {
      if (mm == tiempo) {
        pararExamen();
        return "00:00:00";
      }
      if (pararContador) {
        return `${mm}:${ss}:${cm}`;
      }
    }
  };

  return (
    <div className="App">
      <h1 className="timer">{timeFormat(diff)}</h1>
    </div>
  );
}

export default Timer;
