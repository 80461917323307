import React from "react";

function Temitas(props) {
  const { temas } = props;
  let temasLive = [];
  if (temas) {
    let claves = Object.keys(temas);
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      temasLive.push(temas[clave]);
    }
  }
  return (
    <>
      {temasLive.map((item, i) => (
        <p key={i}>{item.tiempo + " - " + item.nombre}</p>
      ))}
    </>
  );
}

export default Temitas;
