import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import Select from "react-select";
import "../assets/css/Renovaciones.css";
import MercadoPago from "../components/MercadoPago";
import Piza from "../assets/statics/pizza.png";

const Renovaciones = (props) => {
  const { cliente, cursoscomprados, productos } = useContext(Contexto);
  const [disable, setDisable] = useState(true);
  const [planSeleccionado, setPlanSeleccionado] = useState("");
  const [cursos, setCursos] = useState("");
  const [selectExpand, setSelectExpand] = useState(null);
  const [cursoElegido, setCursoElegido] = useState(null);
  const [leyenda, setLeyenda] = useState("");
  // eslint-disable-next-line
  const [diasTotalesAgregar, setDiasTotalesAgregar] = useState(0);
  const [expandSelected, setExpandSelected] = useState({
    dias: 0,
    precio: 0,
    producto: "",
    img_prod: "",
    nombre: "",
  });
  let [producto, setProducto] = useState("");
  let [totalPesos, setTotalPesos] = useState(0);
  let [totalDolar, setTotalDolar] = useState(0);
  let [descuentxs, setDescuentxs] = useState(0);
  let [img_prod, setImg_prod] = useState("");
  let [planes, setPlanes] = useState("");

  var imgProd = "";
  var carrito = 0;
  var regalado = "none";

  useEffect(() => {
    if (cursoscomprados) {
      cargarCursos();
    }
  }, [cursoscomprados]);
  const cargarCursos = (e) => {
    let arr = [];
    let claves = Object.keys(cursoscomprados);
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      // valores[clave]
      arr.push({
        value: cursoscomprados[clave],
        label: cursoscomprados[clave].info.nombre,
      });
    }
    setCursos(arr);
  };
  const cargarPlanes = (e) => {
    setPlanSeleccionado(null);
    setCursoElegido(e.value);
    setDisable(false);
    let cursoActual = cursos.filter((item) => item.label == e.label);
    let opcionesActuales = cursoActual[0].value.info.renovacion;
    setPlanes(opcionesActuales);
  };
  const planElegido = (e) => {
    setPlanSeleccionado(e.value);
  };

  let calcularTodo = () => {
    let hoy = Date.now();
    //let ahora = hoy.getTime();
    let diasTotales = "";
    //////// Lo que le sumamos
    let agregamos = 1000 * 60 * 60 * 24 * planSeleccionado.dias;
    // let leSumamos = agregamos / 1000 / 60 / 60 / 24;
    let txt = "";
    if (Math.round(cursoElegido.restantes) > 0) {
      let quedanSegundos = cursoElegido.restantes * 1000 * 60 * 60 * 24;

      diasTotales = quedanSegundos + agregamos;
    } else {
      diasTotales = agregamos;
    }
    let vencimiento = diasTotales + hoy;
    let enDiasSon = agregamos / 1000 / 60 / 60 / 24;
    setProducto("renovacion-" + cursoElegido.curso + "-" + vencimiento);
    setTotalPesos(planSeleccionado.precio);

    let txtFinal = `Renovamos ${
      cursoElegido.curso
    } le agregamos: ${enDiasSon} le quedan en total:${
      diasTotales / 1000 / 60 / 60 / 24
    }`;
    setLeyenda(txtFinal);
  };

  useEffect(() => {
    if (cursoElegido != null && planSeleccionado != null) {
      calcularTodo();
    }
  }, [planSeleccionado, cursoElegido]);

  return (
    <>
      <div className="contenido-loco">
        <div className="comprar__wrapper">
          <h2 className="itemCarrito--h2">Estás a punto de Renovar: </h2>
          <p>Seleccioná un curso:</p>
          <Select
            defaultValue={cursoElegido}
            onChange={cargarPlanes}
            options={cursos}
          />{" "}
          <br />
          <p>Seleccioná un plan:</p>
          <Select onChange={planElegido} options={planes} />
          <br />
          <h5>{leyenda}</h5>
          <hr />
          {cliente && (
            <MercadoPago
              precio={totalPesos}
              nombre={cliente.nombre}
              username={cliente.username}
              img_prod={imgProd}
              uid={cliente.uid}
              email={cliente.email}
              renovaciones={producto}
              carrito={[]}
              regalito={regalado}
            ></MercadoPago>
          )}
        </div>
        <div className="clorcito off">
          <img src={Piza} alt="" className="piz" />
        </div>
      </div>
    </>
  );
};

export default Renovaciones;
