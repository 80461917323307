import React, { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import BOT from "../assets/statics/heydevbot.png";
import "../assets/css/InfoPrograma.css";
import EnviarReto from "./EnviarReto";
function InfoPrograma(props) {
  const { cursoscomprados, cliente } = useContext(Contexto);
  const { nombre, curso } = props;
  const [discord, setDiscord] = useState("");
  useEffect(() => {
    if (cliente) {
      setDiscord(cliente.discord);
    }
  }, [cliente]);
  return (
    <>
      <hr className="hrr" />
      <div className="programa">
        <img src={BOT} alt="HeyDevBot" />
        {discord && nombre == "summerhack23" && (
          <div className="datita">
            Presentes:{discord.presente} <br />
            Retos entregados: {discord.retos} de 5
          </div>
        )}
      </div>
    </>
  );
}

export default InfoPrograma;
