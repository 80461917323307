import React from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Home.css";
import Poster from "../assets/statics/poster.jpg";
import Curso from "../components/Curso";
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Home() {
  const { productos, seteos } = useContext(Contexto);
  useEffect(() => {}, [seteos]);
  return (
    <>
      <div
        className="homeHero"
        style={{ background: seteos && seteos.home.colorin }}
      >
        <div className="contenedorBanner off">
          <img
            src={seteos && seteos.home.poster}
            alt=""
            className="poster-mobile off ppp"
          />
          {seteos && (
            <a
              href={seteos.home.ctaUrl}
              target={seteos.home.target}
              rel="noreferrer"
              className="cta ctaDesktop"
              style={{
                background: seteos ? seteos.home.ctaColor : "",
                color: seteos ? seteos.home.ctaTXTColor : "",
              }}
            >
              {seteos.home.cta}
            </a>
          )}
        </div>

        <img
          src={seteos && seteos.home.posterMobile}
          alt=""
          className="poster on"
        />
        {seteos && (
          <a
            href={seteos.home.ctaUrl}
            target={seteos.home.target}
            rel="noreferrer"
            className="cta on "
            style={{
              background: seteos ? seteos.home.ctaColor : "",
              color: seteos ? seteos.home.ctaTXTColor : "",
            }}
          >
            {seteos.home.cta}
          </a>
        )}
      </div>
      {productos &&
        /*eslint-disable */
        productos.map((item, i) => {
          if (item.grupo === "fjs") {
            /*eslint-disable */

            return <Curso key={i} {...item}></Curso>;
          }
        })}

      {productos &&
        /*eslint-disable */

        productos.map((item, i) => {
          if (item.grupo === "fsd") {
            /*eslint-disable */
            return <Curso key={i} {...item}></Curso>;
          }
        })}

      {productos &&
        /*eslint-disable */

        productos.map((item, i) => {
          if (item.grupo === "free") {
            /*eslint-disable */
            return <Curso key={i} {...item}></Curso>;
          }
        })}
      <section className="home "></section>
    </>
  );
}

export default Home;
