import React from "react";
import Contexto from "../context/Contexto";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import ExamenesPregunta from "../components/ExamenesPregunta";
import { useState } from "react";
import { useEffect } from "react";
import Timer from "../components/Timer";
import InfoExamen from "../components/InfoExamen";
import ExamenesMal from "../components/ExamenesMal";
import ExamenesBien from "../components/ExamenesBien";
function Examenes() {
  const { examenes, bajarCertificado, usuario, guardarDatos, cliente } =
    useContext(Contexto);
  const [preguntas, setPreguntas] = useState([]);
  const location = useLocation();
  const [laUrl, setLaUrl] = useState("");
  const [tiempo, setTiempo] = useState(10);
  const [comenzar, setComenzar] = useState(false);
  const [examenTerminado, setExamenTerminado] = useState(false);
  const [resultado, setResultado] = useState(false);
  const [pararContador, setPararContador] = useState(true);
  const [umbral, setUmbral] = useState(8);
  const [okWinter, setOkWinter] = useState(false);
  function dameUnRandom(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  let preguntasElegidas = [];

  let armaExamen = (cantidadPreguntas, maximo) => {
    if (examenes[location.pathname.substr(10)]) {
      while (preguntasElegidas.length < cantidadPreguntas) {
        let qran =
          examenes[location.pathname.substr(10)][dameUnRandom(1, maximo)];
        if (!preguntasElegidas.includes(qran)) {
          preguntasElegidas.push(qran);
        } else {
          // console.log("repetida");
        }
      }
      setPreguntas(preguntasElegidas);
    }
  };
  let malas = [];
  useEffect(() => {
    setLaUrl(location.pathname.substr(10));
    if (examenes != undefined && examenes[location.pathname.substr(10)]) {
      let uRL = location.pathname.substr(10);
      if (
        uRL == "winterhack" ||
        uRL == "winterhack23" ||
        uRL == "designseason2" ||
        uRL == "designseason3" ||
        uRL == "summerhack23"
      ) {
        armaExamen(40, 78);
        setTiempo(40);
        setUmbral(32);
        if (cliente) {
          //contando presentes asi:           if (cliente.discord.retos >= 3 && cliente.discord.presente >= 40) {
          //
          if (cliente.discord.retos >= 3) {
            setOkWinter(true);
          }
        }
        if (uRL == "designseason2") {
          setOkWinter(true);
        }
      } else {
        armaExamen(10, 20);
      }
    }
  }, [examenes, cliente]);
  let puntajeFinal = [];
  let setPuntage = (key, valor, pregunta, respuesta) => {
    let valorin = parseInt(valor.slice(-1));
    puntajeFinal[key] = valorin;
    //console.log(puntajeFinal);
    //(puntajeFinal, valorin);
    if (valorin == 0) {
      let armado = {};
      armado.pregunta = pregunta;
      armado.respuesta = respuesta;
      armado.numero = key;
      malas[key] = armado;
    }
  };
  let fecha = Date.now();
  let verResultados = () => {
    setPararContador(false);
    setExamenTerminado(true);
    let score = [];
    for (let props in puntajeFinal) {
      if (puntajeFinal[props] == 1) {
        score.push(props);
      }
    }
    if (score.length >= umbral) {
      setResultado(true);
      guardarDatos(`/usuarios/${usuario.uid}/examenes`, {
        [location.pathname.substr(10)]: {
          fechaUltimoIntento: fecha,
          aprobado: true,
          malas: malas,
        },
      });
    } else {
      guardarDatos(`/usuarios/${usuario.uid}/examenes`, {
        [location.pathname.substr(10)]: {
          fechaUltimoIntento: fecha,
          aprobado: false,
          malas: malas,
        },
      });
    }
    // console.log("score", score.length);
  };
  const iniciaExamen = () => {
    setComenzar(true);
  };
  let pararExamen = () => {
    console.log("pararExamen = ()");
    setExamenTerminado(true);
    verResultados();
  };
  return (
    <>
      <div className="contenedorPre">
        {!comenzar ? (
          <>
            <InfoExamen
              okWinter={okWinter}
              comenzar={iniciaExamen}
              curso={location.pathname.substr(10)}
              laUrl={laUrl}
            />
          </>
        ) : (
          <>
            {examenTerminado ? (
              resultado ? (
                <ExamenesBien
                  bajarCertificado={bajarCertificado}
                  curso={location.pathname.substr(10)}
                ></ExamenesBien>
              ) : (
                <ExamenesMal />
              )
            ) : (
              <Timer
                tiempo={tiempo}
                pararExamen={pararExamen}
                pararContador={pararContador}
              ></Timer>
            )}

            {preguntas &&
              !examenTerminado &&
              preguntas.map((item, i) => (
                <ExamenesPregunta
                  {...item}
                  key={i}
                  posicion={i}
                  setPuntage={setPuntage}
                />
              ))}
            <button onClick={verResultados} className="btn-loco botonTermine">
              {" "}
              Terminé
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default Examenes;
