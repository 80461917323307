//TYPES

const LISTAME_PRODUCTOS = "LISTAME_PRODUCTOS";
const AGREGAR_CARRITO = "AGREGAR_CARRITO";
const ELIMINAR_CARRITO = "ELIMINAR_CARRITO";
const DAME_WINTER = "DAME_WINTER";
const USUARIO_LOGUEADO = "USUARIO_LOGUEADO";
const DESLOGUEAR_USUARIO = "DESLOGUEAR_USUARIO";
const INFO_CLIENTE = "INFO_CLIENTE";
const CURSO_COMPRADO = "CURSO_COMPRADO";
const INFLUENCERS = "INFLUENCERS";
const PREMIOS = "PREMIOS";
const AGREGAR_PACK = "AGREGAR_PACK";
const MIS_SETEOS = "MIS_SETEOS";
const ISLOAD = "ISLOAD";
const ESTAMOS_ENTRANDO = "ESTAMOS_ENTRANDO";
const ESTAMOS_CREANDO = "ESTAMOS_CREANDO";
const NANDAR_MENSAJE = "NANDAR_MENSAJE";
const DONDE_VOY = "DONDE_VOY";
const VER_CURSO = "VER_CURSO";
const CARRITO_INICIAL = "CARRITO_INICIAL";
const ADD_INFO_CLIENTE = "ADD_INFO_CLIENTE";
const CAMBIA_PRECIO = "CAMBIA_PRECIO";
const INTENTO_PASCUAS = "INTENTO_PASCUAS";

const EXAMENES = "EXAMENES";

export default function Reducer(state, action) {
  const { payload, type } = action;
  switch (type) {
    case USUARIO_LOGUEADO:
      return { ...state, usuario: payload };
    case INFLUENCERS:
      return { ...state, influencers: payload };
    //////// todo lo de premios de pascuas
    case INTENTO_PASCUAS:
      let hay = state.premios.llaves.findIndex(
        (ite) => ite.llave == payload && ite.nombre == "------------------"
      );
      return {
        ...state,
        winner: hay,
      };

    case PREMIOS:
      return { ...state, premios: payload };
    case VER_CURSO:
      return { ...state, verCurso: payload };
    case NANDAR_MENSAJE:
      return { ...state, mensajes: payload };
    case EXAMENES:
      return { ...state, examenes: payload };
    case DESLOGUEAR_USUARIO:
      return { ...state, usuario: payload };
    case INFO_CLIENTE:
      return { ...state, cliente: payload };
    case ADD_INFO_CLIENTE:
      return {
        ...state,
        cliente: { ...state.cliente, [payload.curso]: payload.tiene },
      };
    case DONDE_VOY:
      return { ...state, aDondeVoy: payload };
    case CARRITO_INICIAL:
      return { ...state, carrito: payload };

    case CURSO_COMPRADO:
      let ran = state.productos.filter(
        (ite) => ite.url === `/${payload.curso}`
      );
      payload.info = ran[0];

      return {
        ...state,
        cursoscomprados: {
          ...state.cursoscomprados,
          [payload.curso]: payload,
        },
      };
    case LISTAME_PRODUCTOS:
      return {
        ...state,
        productos: [...state.productos, payload],
      };

    case DAME_WINTER:
      return { ...state, winter: payload };
    case MIS_SETEOS:
      return { ...state, seteos: payload };
    case ISLOAD:
      return { ...state, isLoader: payload };
    case ESTAMOS_ENTRANDO:
      return { ...state, isEntrando: payload };
    case ESTAMOS_CREANDO:
      return { ...state, isCreando: payload };

    case AGREGAR_CARRITO:
      return {
        ...state,
        carrito: [
          ...state.carrito,
          state.productos.filter((ite) => ite.url === payload),
        ],
      };

    case CAMBIA_PRECIO:
      let nuevito = state.productos.filter((ite) => ite.url === payload.curso);
      nuevito[0].precio = payload.precio;

      return {
        ...state,
        carrito: [...state.carrito, nuevito],
      };
    case ELIMINAR_CARRITO:
      return {
        ...state,
        carrito: state.carrito.filter((items) => items[0].nombre !== payload),
      };

    case AGREGAR_PACK:
      let Ofertas = payload.split(",");
      let Nombre = payload.split("-");
      let paquete = [];
      let total = 0;
      for (const prop in Ofertas) {
        if (prop !== "0") {
          const x = `/${Ofertas[prop]}`;
          let hijito = state.productos.filter((ite) => ite.url === x);
          total = total + parseInt(hijito[0].precio);
          paquete.push(hijito[0]);
        }
      }
      return {
        ...state,
        carrito: [
          ...state.carrito,
          [
            {
              nombre: Nombre[0],
              productos: paquete,
              paquete: true,
              precio: total,
              descuento: Nombre[1],
            },
          ],
        ],
      };
    default:
      return { state };
  }
}
