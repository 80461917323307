import React, { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";

function CambiarAvatar(props) {
  const { seteos } = useContext(Contexto);
  const { accion } = props;
  const [imagenes, setImagenes] = useState([]);
  useEffect(() => {
    if (seteos) {
      setImagenes(seteos.avatares);
    }
  }, [seteos]);
  const cambiandou = (e) => {
    accion(e.target.src);
  };
  return (
    <>
      <div className="listado-avatares">
        {imagenes.map((item, i) => (
          <img
            key={i}
            className="avatarito"
            src={`https://escueladevrock.com/store/${item}`}
            onClick={cambiandou}
            alt=""
          />
        ))}
      </div>
    </>
  );
}

export default CambiarAvatar;
