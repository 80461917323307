import React from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Header.css";
import Poster from "../assets/statics/poster.jpg";
import Curso from "../components/Curso";
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Gratis() {
  const { productos, seteos } = useContext(Contexto);
  useEffect(() => {
    console.log(seteos);
  }, [seteos]);
  return (
    <>
      {productos &&
        /*eslint-disable */

        productos.map((item, i) => {
          if (item.grupo === "free") {
            /*eslint-disable */
            return <Curso key={i} {...item}></Curso>;
          }
        })}
      <section className="home "></section>
    </>
  );
}

export default Gratis;
