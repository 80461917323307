import React, { useCallback, useState } from "react";
import "../assets/css/MiPerfil.css";
import detectEthereumProvider from "@metamask/detect-provider";
import Meta from "../assets/statics/metamask-seeklogo.com.svg";
//pepeloco
import { useContext, useEffect } from "react";
import Contexto from "../context/Contexto";
import Paises from "../components/Paises";
import CambiarAvatar from "../components/CambiarAvatar";
import Codea from "../assets/statics/editacodea.png";
function MiPerfil() {
  const { usuario, cliente, guardarDatos } = useContext(Contexto);
  const [searchString, setSearchString] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [instagramUsuario, setInstagramUsuario] = useState("");
  const [discordUsername, setDiscordUsername] = useState("");
  const [paisUsuario, setPaisUsuario] = useState("");
  const [cumple, setCumple] = useState("");
  const [quieroNews, setQuieroNews] = useState("");
  const [conectado, setConectado] = useState(false);
  const [adressEth, setAdressEth] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [cambiemos, setCambiemos] = useState(false);
  const [imagenPerfil, setImagenPerfil] = useState(false);
  const [tieneMeta, setTieneMeta] = useState(true);
  const [linkedin, setLinkedin] = useState("");
  let MetaLoco = async () => {
    const provider = await detectEthereumProvider();
    if (provider) {
      setMensaje("Tenés MetaMask, conecta tu billetera!");

      const chainId = await provider.request({
        method: "eth_chainId",
      });
    } else {
      // if the provider is not detected, detectEthereumProvider resolves to null
      setMensaje("Necesitas una billetera de MetaMask!");
      setTieneMeta(false);
    }
  };
  useEffect(() => {
    MetaLoco();

    if (cliente) {
      if (cliente.email) {
        setSearchString(cliente.email);
      }
      if (cliente.quieroNews) {
        setQuieroNews(cliente.quieroNews);
      }
      if (cliente.username) {
        setSearchUsername(cliente.username);
      }
      if (cliente.linkedin) {
        setLinkedin(cliente.linkedin);
      }
      if (cliente.instagram) {
        setInstagramUsuario(cliente.instagram);
      }
      if (cliente.discord) {
        setDiscordUsername(cliente.discord.username);
      }
      if (cliente.pais) {
        setPaisUsuario(cliente.pais);
      }
      if (cliente.cumple) {
        setCumple(cliente.cumple);
      }
      if (cliente.QuieroNews) {
        setQuieroNews(cliente.QuieroNews);
      }
      if (cliente.profile_picture) {
        setImagenPerfil(cliente.profile_picture);
      }
    }
  }, [cliente]);
  const paisQueViene = (pais) => {
    setPaisUsuario(pais);
  };

  let handleClick = () => {
    console.log("guardame");
    if (cliente.discord) {
      cliente.discord.username = discordUsername;
    }
    guardarDatos("/usuarios/" + usuario.uid, {
      username: searchUsername,
      email: searchString,
      instagram: instagramUsuario,
      discord: cliente.discord ? cliente.discord : "",
      pais: paisUsuario,
      cumple: cumple,
      linkedin: linkedin,
      QuieroNews: quieroNews,
      profile_picture: imagenPerfil,
    });
  };
  const handleCheck = (e) => {
    console.log(e.target.checked);
    setQuieroNews(e.target.checked);
  };
  useEffect(() => {
    if (localStorage.getItem("estamosConectados") === "true") {
      connect();
    } else {
      disconnect();
    }
  }, []);

  const connect = async () => {
    await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((data) => {
        setConectado(true);
        localStorage.setItem("estamosConectados", "true");
        setAdressEth(data);
      });

    localStorage.setItem("estamosConectados", true);
  };

  const disconnect = () => {
    localStorage.removeItem("estamosConectados");
    setAdressEth("");
    setConectado(false);
  };
  let cambiar = () => {
    let dale = !cambiemos;
    setCambiemos(dale);
  };
  let guardarNuevo = (img) => {
    setImagenPerfil(img);
  };

  return (
    <>
      {usuario && (
        <>
          <div className="contenedorPerfil">
            <div className="datasPerfil">
              <button onClick={handleClick} className="txtLetrita btn-loco">
                Guardar cambios
              </button>
              <img
                src={imagenPerfil}
                alt=""
                className="foto-de-perfil"
                onClick={() => {
                  cambiar();
                }}
              />
              {cambiemos && (
                <>
                  <CambiarAvatar accion={guardarNuevo}></CambiarAvatar>
                </>
              )}
              <br />
              Email
              <input
                type="text"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                disabled
              ></input>
              Nombre de Usuario
              <input
                type="text"
                value={searchUsername}
                placeholder="Usuario"
                onChange={(e) => setSearchUsername(e.target.value)}
              ></input>
              Tu @instagram
              <input
                type="text"
                value={instagramUsuario}
                placeholder="tu instagram"
                onChange={(e) => setInstagramUsuario(e.target.value)}
              ></input>
              Tu Lunkedin
              <input
                type="text"
                value={linkedin}
                placeholder="tu linkedin"
                onChange={(e) => setLinkedin(e.target.value)}
              ></input>
              Usuario de Discord
              <input
                type="text"
                value={discordUsername}
                onChange={(e) => setDiscordUsername(e.target.value)}
                disabled
              ></input>
              Fecha nacimiento dd/mm/yy
              <input
                type="text"
                value={cumple}
                placeholder="Cumpleaños"
                onChange={(e) => setCumple(e.target.value)}
              ></input>
              Tu país
              <Paises
                accion={paisQueViene}
                seleccionado={paisUsuario}
                placeholder="Tu país"
              />
              <div className="quiero">
                <input
                  type="checkbox"
                  id="quieroNews"
                  onChange={handleCheck}
                  checked={quieroNews}
                ></input>
                <label htmlFor="quieroNews">
                  Quiero recibir newsletters. {quieroNews}
                </label>
              </div>
              <img src={Meta} alt="" className="logoMeta" />
              <br />
              {conectado ? (
                <>
                  <br />
                  Estás conectado a Etherium. <br />
                  <strong> Tu direccion es:</strong>
                  <span className="direccion">{adressEth}</span>
                  <br /> <br />
                  <button
                    onClick={() => {
                      disconnect();
                    }}
                    className="txtLetrita btn-loco"
                  >
                    Desconectar CodeaRock de Eth
                  </button>
                </>
              ) : (
                <>
                  {mensaje}
                  <br /> <br />
                  {tieneMeta && (
                    <button
                      onClick={() => {
                        connect();
                      }}
                      className="txtLetrita btn-loco"
                    >
                      Conectar wallet
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="colorcito off">
              <img src={Codea} alt="CodeaRock.com" className="off codealoco" />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MiPerfil;
