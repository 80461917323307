// file = Html5QrcodePlugin.jsx

import React, { useEffect } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useNavigate } from "react-router-dom";
const Leeme = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    function onScanSuccess(decodedText, decodedResult) {
      // console.log(`Code scanned = ${decodedText}`, decodedResult);
      let incluye = decodedText.split("/");
      //console.log(incluye);
      if (incluye[2] == "codearock.com") {
        console.log(incluye[4]);
        navigate(`/certificados/${incluye[4]}`);
        html5QrcodeScanner
          .clear()
          .then((_) => {
            // the UI should be cleared here
          })
          .catch((error) => {
            // Could not stop scanning for reasons specified in `error`.
            // This conditions should ideally not happen.
          });
      }
    }
    var html5QrcodeScanner = new Html5QrcodeScanner("qr-reader", {
      fps: 10,
      qrbox: 250,
    });
    html5QrcodeScanner.render(onScanSuccess);
    //cuand el componente se desmonta
    return () => {
      html5QrcodeScanner = null;
    };
  }, []);

  // beware: id must be the same as the first argument of Html5QrcodeScanner
  return <div id="qr-reader" style={{ width: "600px" }}></div>;
};
export default Leeme;
