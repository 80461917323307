import "../assets/css/CyberZombie.css";

function CyberZombie(props) {
  const { restantes, activaCyberZombie } = props;
  const handleActivaCyberZombie = () => {
    activaCyberZombie();
  };
  if (restantes > 0) {
    return (
      <>
        <div className="zombie">
          <img
            className="logocz"
            src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExaWZxc3ZtdTNjYzUweDB1anZ6enhqMHBtODMwbW5iZTY5cms3bW1yaiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/kHBru9Sfm84fNnC72K/giphy.webp"
            alt=""
          />
          <p>
            "¡Te quedan {restantes} días para activar tu Año Zombie! 🧟‍♂️ Desde el
            momento en que lo actives, tendrás acceso a todos los cursos durante
            un año completo. ¿Estás listo para vivir un año de aprendizaje sin
            límites? "
          </p>
          <button
            className="cursoComprado--btn  btn-loco"
            onClick={() => {
              handleActivaCyberZombie();
            }}
          >
            ¡Actívalo ahora!
          </button>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default CyberZombie;
