import React, { useState } from "react";

function ImgHamburguesa(props) {
  const { fill, width, height, accion, Hover } = props;

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <button
      onClick={accion}
      className="transparente"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        onClick={accion}
        id="Contours"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 427.16 288.68"
        style={{
          fill: isHovering ? Hover : fill,
          color: isHovering ? fill : "",
          width: width,
          height: height,
        }}
      >
        <path d="M339.5,46.55c10.65-1.97,19.12-7.92,27.95-13,12.11-6.97,24.57-13.03,38.26-16.27,3.46-.82,6.8-2.48,10.28-2.8,2.48-.22,5.3,.76,7.61,1.92,3.75,1.89,4.84,7.06,1.84,10.05-4.92,4.9-9.91,10.02-15.73,13.65-13.82,8.62-27.95,16.81-42.38,24.38-7.31,3.83-15.35,6.56-23.36,8.67-10,2.65-20.3,5.81-30.46,5.79-18.36-.03-35.62-5.66-51.52-15.28-8.32-5.04-16.93-9.78-25.87-13.54-2.51-1.06-7.06,1.24-10.02,3.06-4.73,2.9-8.98,6.62-13.31,10.15-2.32,1.89-4.35,4.13-6.53,6.18-7.7,7.22-15.44,14.82-27.09,13.59-4.27-.45-8.52-2.75-12.39-4.91-10.31-5.76-20.94-11.18-30.43-18.11-8.42-6.15-15.43-14.2-23.15-21.32-8.86-8.18-18-16.34-29.97-19.27-9.19-2.25-17.58,1.87-24.81,7.31-7.67,5.77-15.27,11.75-20.25,20.35-1.2,2.08-3.63,3.43-5.43,5.19-2.65,2.58-5.25,2.57-7.9,.04-2.76-2.65-5.38-5.14-3.64-9.71,4.24-11.17,11.95-19.68,20.72-27.31,4.15-3.61,8.66-7.05,13.53-9.53C60.93,3.05,66.51,.5,73.12,.13c14.44-.82,27.52,2.3,38.89,11.24,5.89,4.63,11.38,9.81,16.77,15.03,11.84,11.47,24.67,21.51,39.65,28.61,1.68,.8,3.27,1.8,4.83,2.82,5.69,3.72,8.34,3.27,13.4-1.38,9-8.27,17.86-16.76,27.51-24.23,4.63-3.59,10.53-5.51,16.95-6.13,12.74-1.24,22.94,4.56,33.45,10.02,8.5,4.42,16.78,9.36,25.6,13.03,8.8,3.66,18.34,4.87,27.84,3.07,7.17-1.36,14.17-3.57,21.49-5.66Z" />
        <path d="M189,268.05c-8.58,5.43-16.01,11.71-25.28,15.83-11.64,5.18-23.31,6.14-34.65,3.01-9.93-2.73-19.26-8.34-28.22-13.74-10.23-6.17-19.71-13.58-29.61-20.32-5.29-3.6-11.41-3.72-17.51-3.46-1.54,.06-3.11,1.01-4.59,.86-6.77-.68-12.02,2.87-16.73,6.65-6.41,5.14-12.17,11.09-18.03,16.88-2.84,2.81-5.69,4.78-9.62,2.5-3.94-2.29-6-7.9-3.94-11.87,1.67-3.2,4.05-6.1,6.47-8.81,7.33-8.22,13.81-17.5,24.24-22.23,13.79-6.25,27.65-8.22,42.68-2.93,12.51,4.4,21.61,13.24,32.13,20.3,7.46,5.01,15.09,10.43,23.45,13.26,11.79,3.98,23.69,1.78,34.02-5.78,8.35-6.11,16.32-12.73,24.82-18.61,5.69-3.94,12.24-6.39,19.37-6.25,11.7,.23,23.41,.36,35.07,1.11,4.75,.31,9.36,2.38,14.1,3.22,7.8,1.39,15.64,2.68,23.51,3.56,6.32,.71,12.71,.79,19.08,1.07,12.18,.54,23.07-3.35,32.55-10.67,14.34-11.07,29.33-21.11,45.14-29.91,4.01-2.23,8.38-3.98,12.78-5.32,5.14-1.57,9.37,4.73,7.72,10.77-3.2,11.65-11.87,18.53-21.05,25.21-7.1,5.17-13.65,11.08-20.67,16.37-5.01,3.77-10.1,7.63-15.68,10.42-6.72,3.36-13.98,5.66-21.03,8.35-1.16,.44-2.43,.71-3.67,.77-6.91,.39-13.87,1.3-20.75,.9-14.53-.85-28.98-2.27-42.94-7.23-6.15-2.19-12.95-2.69-19.51-3.59-6.69-.92-13.43-1.45-20.16-2.04-8.52-.74-14.45,4.6-20.74,9.03-.94,.66-1.67,1.63-2.75,2.71Z" />
        <path d="M54.5,128.55c-10.6,5.62-18.86,13.32-25.04,23.15-1.68,2.67-2.19,6.26-6.56,5.6-4.38-.66-5.99-4.12-7.67-7.55-2.92-6,.57-11.04,3.54-15.15,7.79-10.79,17.35-19.83,29.22-26.44,6.93-3.86,13.53-7.43,21.75-8.01,9.77-.68,17.52,3.59,24.06,9.5,11.26,10.18,23.61,18.29,37.15,25.11,11.9,5.99,22.2,14.51,31.52,24.17,6.71,6.95,9.11,5.64,16.3,1.8,11.55-6.18,21.52-14.63,32.83-20.91,6.21-3.45,13.78-4.47,20.76-6.51,8.06-2.36,15.43,.44,22.94,2.92,7.93,2.63,16.01,4.85,24.08,7.03,7.96,2.15,15.95,4.28,24.03,5.87,2.18,.43,4.75-1.15,7.14-1.84,6.69-1.95,13.56-3.45,20.02-5.97,12.11-4.72,24.45-8.93,34.81-17.45,7.02-5.77,15.02-10.35,22.46-15.62,2.02-1.43,3.8-3.31,5.35-5.26,2.49-3.12,11.91-5.2,15.12-3.11,3.25,2.11,4.18,11.17,1.61,15.24-9.62,15.26-22.64,26.9-38.81,34.64-13.88,6.65-27.88,13.31-42.42,18.2-12.83,4.31-26.22,6.51-40.05,1.55-11.22-4.02-23.12-6.15-34.68-9.25-2.66-.71-5.15-2.07-7.77-2.94-13.93-4.64-25,2.33-35.13,9.98-10.83,8.18-22.4,14.82-34.7,20.18-7.05,3.08-13.84,0-19.52-4.24-5.97-4.47-11.06-10.11-16.99-14.66-7.88-6.05-15.77-12.3-24.41-17.09-12.23-6.78-24.15-13.7-34.23-23.58-6.75-6.61-15.68-6.26-24.79-.33-.51,.33-1.13,.49-1.95,.97Z" />
      </svg>
    </button>
  );
}

export default ImgHamburguesa;
