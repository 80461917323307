import React from "react";
import "../assets/css/MiCurso.css";

import { useContext } from "react";
import Contexto from "../context/Contexto";
import { useState } from "react";

function EnviarReto() {
  const { Griseado, bajarCertificado, cliente, guardarDatos, mandarMensajes } =
    useContext(Contexto);
  const [mostrar, setMostrar] = useState("none");
  const mostrarlos = () => {
    if (mostrar == "none") {
      setMostrar("block");
    } else {
      setMostrar("none");
    }
  };

  let guardarlos = () => {
    let url = document.getElementById("reto1").value;
    let retos2 = [];
    if (cliente.retosDS) {
      retos2 = cliente.retosDS.retos2;
    }
    let nombresin = 1;
    if (cliente.retosDS) {
      nombresin = cliente.retosDS.retos2.length + 1;
    }
    let retotxt = {
      nombre: `Reto ${nombresin}`,
      url: url,
    };

    let ref = `usuarios/${cliente.uid}/retosDS`;
    console.log(retos2, ref);

    let otro = retotxt.url.split("/");
    if (otro[3] == "proto") {
      retos2.push(retotxt);
      guardarDatos(ref, { retos2 });
      document.getElementById("reto1").value = "";
    } else {
      mandarMensajes(
        "Recorda que solo tomamos url del tipo: https://www.figma.com/proto/"
      );
    }
  };

  return (
    <>
      <button
        className="cursoComprado--btn  btn-loco topi"
        onClick={() => {
          mostrarlos();
        }}
      >
        Mis Retos
      </button>
      <div className="enviarReto" style={{ display: mostrar }} key="pipo">
        <div className="retitos">
          <input type="text" placeholder="Inserta tu reto aqui" id="reto1" />
          <br /> <br />
          <button
            className="cursoComprado--btn  btn-loco"
            onClick={() => {
              guardarlos();
            }}
          >
            Enviar reto
          </button>{" "}
          <br />
          <br />
          {cliente &&
            cliente.retosDS &&
            cliente.retosDS.retos2 &&
            cliente.retosDS.retos2.map((item, i) => {
              return (
                <>
                  <a href={item.url} key={i + "kk"}>
                    {" "}
                    {item.nombre}
                  </a>{" "}
                  /
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default EnviarReto;
