import React from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Header.css";
import Poster from "../assets/statics/poster.jpg";
import Curso from "../components/Curso";
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Podcast() {
  const { productos, seteos } = useContext(Contexto);
  useEffect(() => {
    console.log(seteos);
  }, [seteos]);
  return (
    <>
      <br />
      <div className="podcast">
        <iframe
          src="https://open.spotify.com/embed/episode/4bEXriZOWLHxDoWwcOnZ0G?utm_source=generator&theme=0"
          width="100%"
          height="200px"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
        <hr className="separador" />
        <iframe
          src="https://open.spotify.com/embed/episode/17OTmrhJTcoiIkWpIYjvYv?utm_source=generator&theme=0"
          width="100%"
          height="152"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>

      <hr />
    </>
  );
}

export default Podcast;
