import React from "react";

function CarritoItem(props) {
  const { nombre, eliminar, logo, colorin } = props;

  return (
    <>
      <div className="soportePaquete" style={{ background: colorin }}>
        <p className="tituloNombre">{nombre}</p>
        <img
          src={`https://escueladevrock.com/${logo}`}
          alt="DevRock"
          className="img-paquete"
        />

        {/* {productos.map((item, i) => {
        return (
          <img key={i} src={`https://escueladevrock.com/${item[0].logo}`} />
        );
      })} */}

        <button
          onClick={() => {
            eliminar(nombre);
          }}
          className="btn-loco"
        >
          Borrar
        </button>
      </div>
    </>
  );
}

export default CarritoItem;
