import { useContext, useState } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Entrar.css";
import Google from "../assets/statics/google.png";

const Entrar = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const {
    loguearUsuario,
    loginUsuarioConEmail,
    mandarMensajes,
    resetPassword,
    estamosEntrando,
    estamosCreando,
  } = useContext(Contexto);
  const conEmail = () => {
    if (email == "" && pass == "") {
      mandarMensajes("Completa porfi Usuario y Password");
    } else if (email == "") {
      mandarMensajes("Completa el campo email porfi");
    } else if (pass == "") {
      mandarMensajes("Te faltó el pass ;)");
    } else {
      loginUsuarioConEmail(email, pass);
      estamosEntrando(false);
      mandarMensajes("a ver, tamo' entrandou");
    }
    // loginUsuarioConEmail(usuario, pass);
  };
  const handlechange = (e) => {
    if (e.target.name == "email") setEmail(e.target.value);
    if (e.target.name == "pass") setPass(e.target.value);
  };
  const recuperar = () => {
    if (email == "") {
      mandarMensajes(
        "Completa el campo email porfi para recuperar tu password"
      );
    } else {
      resetPassword();
    }
  };
  const cerrar = () => {
    estamosEntrando(false);
  };

  const crear = () => {
    estamosCreando(true);
  };
  return (
    <>
      <div className="loader-wraper2">
        <button className="cerrarx" onClick={cerrar}>
          X
        </button>
        <button className="Entrarcongoogle" onClick={loguearUsuario}>
          <img src={Google} alt="" /> Entrar con google
        </button>
        <br />
        <p>O entra con tu email</p>
        <div>
          <input
            type="text"
            className="entrada"
            onChange={handlechange}
            placeholder="Email"
            name="email"
          />
          <br /> <br />
          <input
            type="password"
            className="entrada"
            placeholder="Contraseña"
            onChange={handlechange}
            name="pass"
          />
          <div className="olvidaste">
            <button className="olvidastebtn" onClick={recuperar}>
              ¿Olvidaste tu contraseña?
            </button>
          </div>
          <br />
          <br />
          <button className="Entra" onClick={conEmail}>
            Entrá
          </button>
          <br />
          <br /> <br /> <br /> <br />
          ¿No tienes cuenta?
          <button className="olvidastebtn">
            {" "}
            <strong onClick={crear}>Crear cuenta</strong>{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Entrar;
