import React, { useEffect } from "react";
import { useState } from "react";
import "../assets/css/MercadoPago.css";
import Tarjetas from "../assets/statics/tarjetas.png";
import MPP from "../assets/statics/mp.png";
import Renovaciones from "../containers/Renovaciones";
const MercadoPago = (props) => {
  const {
    precio,
    uid,
    email,
    carrito,
    username,
    regalito,
    renovaciones,
    ceci,
  } = props;
  const [product, setProduct] = useState("");
  const [IMG, setIMG] = useState("");
  const imgProducto = () => {
    if (Object.keys(carrito).length) {
      const cantidad = Object.keys(carrito).length;
      if (cantidad > 1) {
        for (const prop in carrito) {
          if (carrito[prop].cierre) {
            return carrito[prop].img_prod;
          }
        }
      } else {
        return carrito[0].img_prod;
      }
    }
  };

  useEffect(() => {
    misCursos();
  }, [carrito, regalito, renovaciones]);
  const misCursos = () => {
    let cursosOk = "";
    let primero = false;
    for (const prop in carrito) {
      let nombre = "";
      if (!carrito[prop][0].paquete) {
        cursosOk += `${nombre}-${carrito[prop][0].url.substr(1)}`;
        setIMG(carrito[prop][0].img_prod);
      } else {
        for (const pro in carrito[prop][0].productos) {
          let hijo = carrito[prop][0].productos[pro].url.substr(1);
          setIMG(carrito[prop][0].productos[pro].img_prod);

          cursosOk += `-${hijo}`;
        }
      }
      // console.log(carrito[prop][0].url.substr(1));
      // let nombre = carrito[prop].url.substr(1);
      // if (carrito[0][prop].paquete) {
      //   console.log("ES UN PAQUETE");
      // }
    }
    if (regalito) {
      cursosOk = cursosOk + "-regalo-" + email;
    }
    if (!renovaciones) {
      setProduct(cursosOk.substr(1));
    } else {
      console.log("hayrenovacion loca:", renovaciones);
      setProduct(renovaciones);
    }
  };
  return (
    <>
      <form
        className="formularioPAgo"
        name="formulario"
        method="post"
        action={
          !ceci
            ? "https://codeapagos.vercel.app/pagos"
            : "https://talleresacolor.com/TACPagos/"
        }
      >
        <input name="idProducto" value={product} type="hidden" />
        <input name="producto" value={product} type="hidden" />
        <input name="precio" value={precio} type="hidden" />
        <input name="imagen" value={IMG} type="hidden" />
        <input name="nombre" value={username} type="hidden" />
        <input name="uid" value={uid} type="hidden" />
        <input name="mail" value={email} type="hidden" />
        <img src={MPP} className="btnmp" />
        <button type="submit" value="COMPRAR" className="btn btn-success">
          <strong className="tituloPago">COMPRAR EN ARGENTINA</strong> <br />{" "}
          <span className="peque">6 cuotas sin interés</span>
        </button>
        <img src={Tarjetas} alt="tarjetas" className="tarjetas" />
      </form>
    </>
  );
};
export default MercadoPago;
