import React from "react";
import "../assets/css/Header.css";
import "../assets/css/MobileMenu.css";
import ImgCerrar from "./ImgCerrar";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ImgEntrar from "./ImgEntrar";

function MobileMenu(props) {
  const {
    cliente,
    desconectarUsuario,
    Griseado,
    canjearCursos,
    usuario,
    loguearUsuario,
    seteos,
    resetPassword,
  } = useContext(Contexto);
  const navigate = useNavigate();
  const { estado, accion } = props;
  const redireccionar = (url) => {
    accion();
    if (url === "desloguear") {
      desconectarUsuario();
      Griseado("/");
    } else {
      Griseado(url);
    }
  };
  const [codiguito, setCodiguito] = useState("");
  const handleCodigo = (e) => {
    setCodiguito(e.target.value);
  };
  const dameRegalo = () => {
    console.log("dameRegalo()", codiguito);
    canjearCursos({ codigo: codiguito, pibe: usuario.uid });
  };
  return (
    <div className={estado} id="menuMobile">
      <ImgCerrar
        fill="white"
        Hover="#01ff00"
        width="150px"
        height="50px"
        accion={accion}
      ></ImgCerrar>
      <br />
      <br />
      {cliente && (
        <>
          <div className="infoCliente">
            <ul>
              <li>
                {/* <img src={cliente.profile_picture} alt={cliente.userName} /> */}
                <h1 className="">{cliente.username}</h1>
              </li>
              <li>
                <button
                  onClick={() => redireccionar("/miscursos")}
                  className="infoClientebtn"
                >
                  Mis cursos
                </button>
              </li>
              <li>
                <button
                  onClick={() => redireccionar("/miperfil")}
                  className="infoClientebtn"
                >
                  Mi Panel
                </button>
              </li>
              <li>
                <button
                  onClick={() => redireccionar("/renovaciones")}
                  className="infoClientebtn"
                >
                  Renovaciones
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
      <ul>
        {/* A ver */}
        {seteos && seteos.hasOwnProperty("paquetes") && (
          <li>
            <button
              onClick={() => redireccionar("/ofertas")}
              className="infoClientebtn"
            >
              Ofertas
            </button>
          </li>
        )}

        <li>
          <a
            href="https://open.spotify.com/show/5uRPZ5r7bRkW29c5AkppXq"
            target="_white"
            className="infoClientebtn white"
          >
            Pódcasts
          </a>
        </li>
        <li>
          <a
            href="https://devrock.flashcookie.com/"
            target="_blank"
            className="infoClientebtn white"
          >
            Tiendita
          </a>
        </li>
        <li>
          <button
            onClick={() => redireccionar("/gratis")}
            className="infoClientebtn"
          >
            Cursos Gratis
          </button>
        </li>
        <li>
          <button
            onClick={() => redireccionar("/faq")}
            className="infoClientebtn"
          >
            FAQ
          </button>
        </li>
        <button
          onClick={() => {
            redireccionar("desloguear");
          }}
          className="infoClientebtn"
        >
          DESLOGUEAR
        </button>
        {/* <li>
          <button
            onClick={() => {
              loginUsuarioConEmail("jonatan.ariste@gmail.com", "pepeloco");
            }}
            className="infoClientebtn"
          >
            Crear Usuario con email
          </button>
        </li> 
        <li>
          <button
            onClick={() => {
              crearUsuarioConEmail("jonatan.ariste@gmail.com", "pepeloco");
            }}
            className="infoClientebtn"
          >
            login Usuario
          </button>
        </li>
        <hr />
        <li>
          <button
            onClick={() => {
              resetPassword("jonatan.ariste+6669@gmail.com");
            }}
            className="infoClientebtn"
          >
            ResetearPassword
          </button>
        </li>*/}

        <li>
          {cliente && (
            <div className="regalines">
              <h3 className="txtLetrita white"> Tenés un código de regalo?</h3>
              <input
                type="text"
                onChange={handleCodigo}
                className="regalin"
                placeholder="Ingresá tu código"
              />
              <br /> <br />
              <button onClick={dameRegalo} className="infoClientebtn">
                Dame mi regalo
              </button>
              <br />
            </div>
          )}
        </li>
        <li>
          <br />
          <hr />
        </li>
      </ul>

      <ul>
        <li>
          {!cliente && (
            <ImgEntrar
              accion={loguearUsuario}
              className="header-menuHamburguesa"
              fill="white"
              Hover="#01ff00"
              width="50px"
              height="30px"
            ></ImgEntrar>
          )}
        </li>
      </ul>
    </div>
  );
}

export default MobileMenu;
