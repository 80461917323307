import React from "react";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import PregRes from "../components/PregRes";
import { useEffect } from "react";
import imgFaq from "../assets/statics/faq.png";

const Faquestion = () => {
  const { seteos } = useContext(Contexto);
  useEffect(() => {}, [seteos]);

  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1"
        crossorigin="anonymous"
      />
      <div className="container">
        <div className="row SHfaq">
          <div className="col-md-12 ">
            <img src={imgFaq} alt="" className="faqTitulo" />
          </div>
          <div className="col-md-6 ">
            {seteos &&
              seteos.faq.preguntas.map((item, i) => (
                <PregRes {...item}></PregRes>
              ))}
          </div>
          <div className="col-md-6">
            {seteos &&
              seteos.faq.preguntasDos.map((item, i) => (
                <PregRes {...item}></PregRes>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faquestion;
