import goma from "../assets/statics/goma.png";
import React from "react";
import Contexto from "../context/Contexto";
import { useContext } from "react";
import "../assets/css/Examenes.css";

function ExamenesPrevia(props) {
  const { guardarYArrancar, okWinter } = props;
  const { cliente, guardarDatos, usuario } = useContext(Contexto);

  return (
    <>
      <div className="contenedorExamenes">
        <h1>¿Estás listx para el examen?</h1>

        <img src={goma} alt="" className="esperar-imagen" />

        <h3>
          Cuando hagas click en el botón de abajo, van a aparecer en pantalla{" "}
          {okWinter ? 40 : 10}
          preguntas con multiple choice.<br></br>El timer va a empezar a correr
          automáticamente, tenés {okWinter ? 40 : 10} minutos para responder
          todo y para aprobar necesitás contestar bien al menos{" "}
          {okWinter ? 32 : 8} preguntas. <br></br>Si terminás antes de que se
          acaben los {okWinter ? 40 : 10} minutos, podés darle click al botón
          "Terminé" para presentar tu examen; si no, a los {okWinter ? 40 : 10}{" "}
          minutos el examen se va a enviar solo. <br></br>Si rendís mal, vas a
          tener que esperar 7 días para volver a rendir el examen.
        </h3>
        <h2>¿Vamos?</h2>
        <button
          onClick={() => {
            guardarYArrancar();
          }}
          className="btn-loco"
        >
          Arrancar el examen
        </button>
      </div>
    </>
  );
}

export default ExamenesPrevia;
