import React from "react";
function PlayerDevRock(props) {
  const { videoURL } = props;
  return (
    <>
      <iframe
        title="myFrame"
        rows="1"
        id="videoX"
        width="100%"
        height="auto"
        src={videoURL}
        frameBorder="0"
        allowFullScreen
        className="embed-responsive-item"
        style={{ backgroundColor: "black" }}
      ></iframe>
    </>
  );
}

export default PlayerDevRock;
