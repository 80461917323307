import React from "react";
import "../assets/css/Ver.css";

import { useContext, useEffect } from "react";
import Contexto from "../context/Contexto";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import PlayerDevRock from "../components/PlayerDevRock";
import clip from "../assets/statics/adjunto-archivo.png";
function Ver() {
  let rana = -1;
  const location = useLocation();
  const {
    buscarDataCurso,
    verCurso,
    cursoscomprados,
    Griseado,
    cliente,
    salvarProgreso,
  } = useContext(Contexto);
  const [infoCurso, setInfoCurso] = useState([]);
  const [verListado, setVerListado] = useState("none");
  const [videosVistos, setVideosVistos] = useState({});
  const [adjuntos, setAdjuntos] = useState([]);
  const [actual, setActual] = useState({
    url: "https://www.youtube.com/embed/ZciE2pKJTOY?si=jIVFvk609w6sQ8aU",
    posicion: 1,
    fuevisto: "",
  });
  let pathCurso = location.pathname.substr(5);
  useEffect(() => {
    if (cursoscomprados[pathCurso] != undefined) {
      if (cursoscomprados[pathCurso].estado) {
        buscarDataCurso(pathCurso);
      } else {
        Griseado("/renovaciones");
      }
    }
  }, [cursoscomprados]);
  useEffect(() => {
    if (cliente) {
      if (cliente.hasOwnProperty("progreso")) {
        if (cliente.progreso.hasOwnProperty(pathCurso)) {
          if (cliente.progreso[pathCurso].hasOwnProperty("videosVistos")) {
            if (cliente.progreso[pathCurso].videosVistos.length > 0) {
              setVideosVistos(cliente.progreso[pathCurso]);
            }
          } else {
            setVideosVistos({
              total: 0,
              ultimaVivisita: Date.now(),
              videosVistos: [],
            });
          }
        } else {
          setVideosVistos({
            total: 0,
            ultimaVivisita: Date.now(),
            videosVistos: [],
          });
        }
      } else {
        setVideosVistos({
          total: 0,
          ultimaVivisita: Date.now(),
          videosVistos: [],
        });
      }
    }

    if (verCurso != null) {
      preparaAdjuntos(verCurso[1]);
      setInfoCurso(verCurso);
    }
  }, [verCurso]);
  const preparaAdjuntos = (valores) => {
    let claves = Object.keys(valores);
    let adj = [];
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      adj.push(valores[clave]);
    }
    setAdjuntos(adj);
  };
  const play = (e) => {
    let laURL = e.target.attributes.url.value;
    let estadito = e.target.classList.contains("visto");
    let videoActual = {
      url: laURL,
      posicion: parseInt(e.target.attributes.posicion.value),
      fuevisto: estadito,
    };
    setActual(videoActual);
    let enplay = document.querySelectorAll(".enPlay");
    enplay.forEach((box) => {
      box.classList.remove("enPlay");
    });
    e.target.classList.add("enPlay");
    if (verListado == "block") {
      setVerListado("none");
    } else {
      setVerListado("block");
    }
    VerListado();
    //   window.open(laURL, "_blank").focus();
  };

  const marcarVistoBTN = (e) => {
    let vv = videosVistos;
    let puntaje = verCurso[0].puntaje;

    if (vv.videosVistos.indexOf(actual.url) == -1) {
      vv.videosVistos.push(actual.url);
    }

    let activos = document.querySelectorAll("#pepeLoco li button");
    activos[actual.posicion].classList.add("visto");

    vv.ultimaVivisita = Date.now();
    vv.total = (100 / parseInt(puntaje)) * vv.videosVistos.length;

    // ListadoCompleto[0].completo = progreso;
    // setInfoCurso(ListadoCompleto);
    console.log(" salvarProgreso(pathCurso, vv)", vv);
    salvarProgreso(pathCurso, vv);
  };
  const pasarSiguiente = () => {
    let pepe = document.getElementById("pepeLoco");
    let activos = pepe.querySelectorAll("li button");
    console.log("actual:", actual);
    if (actual.posicion < activos.length - 1) {
      let siguiente = actual.posicion;
      siguiente++;
      let estadito = activos[siguiente].classList.contains("visto");

      let videoActual = {
        url: activos[siguiente].attributes.url.value,
        posicion: parseInt(activos[siguiente].attributes.posicion.value),
        fuevisto: estadito,
      };
      let laURL = activos[siguiente].attributes.url.value;

      let enplay = document.querySelectorAll(".enPlay");
      enplay.forEach((box) => {
        box.classList.remove("enPlay");
      });
      activos[siguiente].classList.add("enPlay");
      setActual(videoActual);
    }
  };
  const volverAnterior = () => {
    let pepe = document.getElementById("pepeLoco");
    let activos = pepe.querySelectorAll("li button");
    if (actual.posicion > 0) {
      let nuevaposicion = actual.posicion;

      nuevaposicion--;
      let estadito = activos[nuevaposicion].classList.contains("visto");

      const videoActual = {
        url: activos[nuevaposicion].attributes.url.value,
        posicion: nuevaposicion,
        fuevisto: estadito,
      };
      let laURL = activos[nuevaposicion].attributes.url.value;

      setActual(videoActual);
      let enplay = document.querySelectorAll(".enPlay");
      enplay.forEach((box) => {
        box.classList.remove("enPlay");
      });
      activos[nuevaposicion].classList.add("enPlay");
    }
  };
  const [verAdj, setVerAdj] = useState("none");
  const VerListado = () => {
    setInfoCurso(verCurso);
    if (verListado == "block") {
      setVerListado("none");
    } else {
      setVerListado("block");
    }
    setVerAdj("none");
  };
  const verAdjuntos = () => {
    setVerListado("none");
    if (verAdj == "block") {
      setVerAdj("none");
    } else {
      setVerAdj("block");
    }
  };
  let abrir = (laURL) => {
    window.open(laURL, "_blank").focus();
  };
  return (
    <>
      <section className="verSection">
        <div className="soporteVideos">
          <div className="controles">
            <button
              onClick={() => {
                VerListado();
              }}
              className="btn-loco"
            >
              Ver Listado
            </button>
            <button onClick={volverAnterior} className="btn-loco">
              anterior
            </button>
            <button onClick={pasarSiguiente} className="btn-loco">
              siguiente
            </button>

            <button onClick={marcarVistoBTN} className="btn-loco">
              Marcar Visto ✅
            </button>

            <img src={clip} alt="" className="clipi" onClick={verAdjuntos} />
          </div>
          {verListado && (
            <div
              className="listadin"
              id="listadin"
              style={{ display: verListado }}
            >
              <ul id="pepeLoco">
                {infoCurso &&
                  infoCurso.map((item, i) => {
                    if (item.url != undefined) {
                      rana++;
                    }
                    if (item.hasOwnProperty("nombre")) {
                      let estado = "";
                      let busqueda = videosVistos.videosVistos.indexOf(
                        item.url
                      );
                      if (busqueda >= 0) {
                        estado = "visto";
                      }

                      return (
                        <>
                          {item.url ? (
                            <li key={i}>
                              <button
                                onClick={play}
                                url={item.url}
                                tipo={item.tipo}
                                posicion={rana}
                                className={estado}
                              >
                                {item.nombre}
                              </button>
                            </li>
                          ) : (
                            <>
                              <p className="titilo-listadito">{item.nombre}</p>
                            </>
                          )}
                        </>
                      );
                    }
                  })}
              </ul>
            </div>
          )}

          {verAdjuntos && (
            <div className="listadin" style={{ display: verAdj }}>
              <ul id="gatoloco">
                {adjuntos &&
                  adjuntos.map((item, i) => {
                    return (
                      <li key={i}>
                        <button
                          onClick={() => {
                            abrir(item.url);
                          }}
                          url={item.url}
                        >
                          {item.nombre}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
        </div>
        <PlayerDevRock videoURL={actual.url}></PlayerDevRock>
      </section>
    </>
  );
}

export default Ver;
