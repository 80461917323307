import React from "react";
import Contexto from "../context/Contexto";
import { useContext } from "react";
import { useEffect } from "react";
import { isFocusable } from "@testing-library/user-event/dist/utils";
import { useState } from "react";
import ExamenesEsperar from "./ExamenesEsperar";
import ExamenesPrevia from "./ExamenesPrevia";
import ExamenesWinter from "./ExamenesWinter";

function InfoExamen(props) {
  const { comenzar, curso, okWinter, laUrl } = props;
  const { cliente, guardarDatos, usuario } = useContext(Contexto);
  const [listoParaEmpezar, setListoParaEmpezar] = useState(true);
  const [cuantoFalta, setCuantoFalta] = useState("");
  const [vioTodo, setVioTodo] = useState(0);
  let fecha = Date.now();
  let comenzarExamen = () => {
    if (cliente) {
      if (cliente.examenes) {
        if (cliente.progreso.hasOwnProperty(curso)) {
          setVioTodo(cliente.progreso[curso].total);
        }
        if (cliente.examenes[curso]) {
          let fechaUltimoIntento = cliente.examenes[curso].fechaUltimoIntento;
          let tiempoPasado = fecha - fechaUltimoIntento;
          tiempoPasado = tiempoPasado / 1000 / 60 / 60 / 24;
          const hoy = new Date(fecha);
          if (tiempoPasado < 7) {
            setListoParaEmpezar(false);
            let falta = fechaUltimoIntento + 1000 * 60 * 60 * 24 * 7;
            const fechaProximo = new Date(falta);
            setCuantoFalta(fechaProximo.toLocaleDateString());
          } else {
            setListoParaEmpezar(true);
          }
        }
      }
      if (cliente.progreso.hasOwnProperty(curso)) {
        setVioTodo(cliente.progreso[curso].total);
      }
    }
  };

  useEffect(() => {
    comenzarExamen();
  }, [cliente]);
  let guardarYArrancar = () => {
    if (cliente.examenes) {
      let anteriores = cliente.examenes;
      anteriores[curso] = { fechaUltimoIntento: fecha, aprobado: false };
      guardarDatos(`/usuarios/${usuario.uid}/examenes`, anteriores);
      comenzar();
    } else {
      guardarDatos(`/usuarios/${usuario.uid}/examenes`, {
        [curso]: { fechaUltimoIntento: fecha, aprobado: false },
      });
      comenzar();
    }
  };
  return (
    <>
      {listoParaEmpezar == true && curso && vioTodo > 99 ? (
        <>
          {curso != "winterhack" ? (
            <ExamenesPrevia
              guardarYArrancar={guardarYArrancar}
              okWinter={okWinter}
            ></ExamenesPrevia>
          ) : (
            <ExamenesWinter
              guardarYArrancar={guardarYArrancar}
              okWinter={okWinter}
              cuantoFalta={cuantoFalta}
              vioTodo={vioTodo}
              laUrl={laUrl}
            ></ExamenesWinter>
          )}
        </>
      ) : (
        <>
          <ExamenesEsperar
            cuantoFalta={cuantoFalta}
            vioTodo={vioTodo}
            okWinter={okWinter}
            laUrl={laUrl}
          ></ExamenesEsperar>
        </>
      )}
    </>
  );
}

export default InfoExamen;
