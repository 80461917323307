import React from "react";
import parse from "react-html-parser";
import "../assets/css/Faq.css";

// import renderHTML from "react-render-html";

export default function componentName(props) {
  const { p, r } = props;
  return (
    <>
      <p className="SHpregunta">{parse(p)}</p>
      <p className="SHrespuesta">{parse(r)}</p>
    </>
  );
}
