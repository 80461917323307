import Huevo from "../assets/statics/huevo.png";
import Contexto from "../context/Contexto";
import { useContext, useState, useEffect } from "react";
import "../assets/css/Huevo.css";
import { useNavigate } from "react-router-dom";

function HuevoDePascua(props) {
  const navigate = useNavigate();

  const [texto, setTexto] = useState("");
  const { mandarMensajes, winner, intento, guardarGanador, cliente } =
    useContext(Contexto);
  const handleChange = (e) => {
    setTexto(e.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (texto != "records") {
        intento(texto);
        mandarMensajes(`Mucha suerte!`);
      } else {
        navigate("/records");
      }

      // switch (texto.toLocaleLowerCase()) {
      //   case "ranacorrige":
      //     mandarMensajes("🐸");
      //     break;
      //   case "colorclub":
      //     mandarMensajes("ehhh vo ke juga colorclub XD");
      //     break;
      //   default:
      //     mandarMensajes(`Holi a ke estas jugando`);
      // }
    }
  };

  useEffect(() => {
    if (winner != -1) {
      guardarGanador(texto);
      navigate("/records");
      mandarMensajes(`🥳 Te ganaste una beca!!!🥳`);
    }
  }, [winner]);

  return (
    <>
      {/* {cliente && (
        <div className="EasterEgg" onKeyDown={handleKeyPress}>
          <img src={Huevo} alt="" className="huevito" />
          <input type="text" className="sinBordes" onChange={handleChange} />
        </div>
      )} */}
    </>
  );
}

export default HuevoDePascua;
