import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/Footer.css";
import HuevoDePascua from "./HuevoDePascua";

function Footer() {
  return (
    <>
      <HuevoDePascua></HuevoDePascua>
      <footer>
        <div className="contenido-footer">
          &nbsp;Copyright CodeaRock®™
          <br />
          <a href="mailto:rana@codearock.com" target="_blank" rel="noreferrer">
            Contacto
          </a>
          <a
            href="https://www.instagram.com/codearock/"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.youtube.com/user/jonatanariste"
            target="_blank"
            rel="noreferrer"
          >
            Youtube
          </a>
          <a
            href="https://www.twitch.tv/codearock"
            target="_blank"
            rel="noreferrer"
          >
            Twitch
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
