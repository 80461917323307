import React from "react";
import parse from "react-html-parser";

const CursoFAQ = (props) => {
  const { pregunta, respuesta } = props;
  return (
    <>
      <h2 className="txtLetrita"> {pregunta}</h2>
      <hr className="txtLetrita" />
      <div> {parse(respuesta)}</div>
    </>
  );
};

export default CursoFAQ;
