import React from "react";
import { useContext, useEffect } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Header.css";
import Logo from "./ImgLogo";
import Countdown from "react-countdown";
import MobileMenu from "./MobileMenu";
import ImgHamburguesa from "./ImgHamburguesa";
import ImgEntrar from "./ImgEntrar";
import { useState } from "react";
import Ofertuli from "./Ofertuli";
import BlackSabbath from "./BlackSabbath";
import ImgCarrito from "./ImgCarrito";
import dslogo from "../assets/statics/DS/dslogo-05.svg";

import toast, { Toaster } from "react-hot-toast";
//const notify = () => toast("Here is your toast.");
import { useLocation } from "react-router-dom";
// #ABFF24 Verde DS
// #FD83FF rosa DS
function Header() {
  const location = useLocation();

  const [fueraHome, setFueraHome] = useState(false);

  const {
    loguearUsuario,
    mensajes,
    mandarMensajes,
    Griseado,
    usuario,
    cliente,
    seteos,
    estamosEntrando,
    estamosCreando,
  } = useContext(Contexto);
  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };

    // Get the header
    var header = document.getElementById("myHeader");

    // Get the offset position of the navbar
    var sticky = header.offsetTop;
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }, []);

  useEffect(() => {
    if (mensajes.mensaje != "") toast(mensajes.mensaje);
  }, [mensajes]);

  const [estado, setEstado] = useState(null);
  const [designSeason, setDesignSeason] = useState(false);
  const [fecha, setFecha] = useState("");
  const toggleMenu = () => {
    if (estado === "MenuOff" || estado === null) {
      setEstado("MenuOn");
    } else if (estado === "MenuOn") {
      setEstado("MenuOff");
    }
  };
  useEffect(() => {
    if (seteos) {
      setFecha(seteos.home.fecha);
      setFueraHome(seteos.home.hayOfertas);
      console.log("setFueraHome", seteos.home.hayOfertas);
    }
  }, [seteos]);

  useEffect(() => {
    if (seteos && !seteos.home.hayOfertas) {
      if (location.pathname !== "/") {
        setFueraHome(true);
      } else {
        setFueraHome(false);
      }
    }

    if (location.pathname == "/designseason") {
      setDesignSeason(true);
    }
  }, [location.pathname]);

  let entramo = () => {
    //console.log("ENTRAMO");
    estamosEntrando(true);
  };

  let creamo = () => {
    //console.log("ENTRAMO");
    estamosCreando(true);
  };
  return (
    <>
      {!fueraHome && (
        <div
          className="ofertulis"
          style={{ background: seteos && seteos.home.colorFondoOferta }}
        >
          <div
            className="off "
            style={{ background: seteos && seteos.home.colorFondoOferta }}
          >
            <img
              src={seteos && seteos.home.logoOferta}
              alt=""
              className="ofertulis"
            />
          </div>
          <div className="textos">
            {
              <p className="tituloContador">
                {" "}
                {seteos && seteos.home.fraseContador}
              </p>
            }
            {fecha != "" && <Countdown date={fecha} />}
            {
              <p>
                {" "}
                Días &nbsp;&nbsp;&nbsp;Horas&nbsp; &nbsp; Min &nbsp;&nbsp;&nbsp;
                Seg
              </p>
            }

            <div className="textoFecha"></div>
          </div>
        </div>
      )}
      {/* <div style={{ visibility: this.state.driverDetails.firstName != undefined? 'visible': 'hidden'}}></div> */}

      <header
        className={!designSeason ? "header" : "header designStyle"}
        id="myHeader"
      >
        <ImgHamburguesa
          accion={toggleMenu}
          className="header-menuHamburguesa off"
          fill="white"
          Hover="#01ff00"
          width="30px"
          height="30px"
        ></ImgHamburguesa>
        {!designSeason ? (
          <div
            className="logoLink"
            onClick={() => {
              Griseado("/");
            }}
          >
            <Logo
              fill="white"
              width="130px"
              height="30px"
              className="logo"
              Hover="#01ff00"
            />
          </div>
        ) : (
          <img
            src={dslogo}
            alt=""
            className="dslogo"
            onClick={() => {
              Griseado("/designseason");
            }}
          />
        )}

        {!cliente ? (
          <div className="crearEntrar">
            <button className="creame" onClick={entramo}>
              Entrar
            </button>

            <button className="creame vive" onClick={creamo}>
              Crear cuenta
            </button>
          </div>
        ) : (
          <img
            src={
              cliente.profile_picture
                ? cliente.profile_picture
                : "https://escueladevrock.com/store/13.jpg"
            }
            alt={cliente.username}
            onClick={toggleMenu}
            className="foto-perfil"
          />
        )}
      </header>
      <MobileMenu
        estado={estado}
        accion={toggleMenu}
        className="off"
      ></MobileMenu>
      <div
        onClick={() => {
          Griseado("/carrito");
        }}
      >
        <ImgCarrito
          fill="white"
          Hover="white"
          width="50px"
          height="30px"
        ></ImgCarrito>
      </div>
    </>
  );
}

export default Header;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
