import tiempo from "../assets/statics/tiempo.png";
import React from "react";
import Contexto from "../context/Contexto";
import { useContext } from "react";
import "../assets/css/Examenes.css";

function ExamenesEsperar(props) {
  const { cuantoFalta, vioTodo, okWinter, laUrl } = props;
  const { cliente, guardarDatos, usuario } = useContext(Contexto);

  return (
    <>
      <div className="contenedorExamenes">
        <img src={tiempo} alt="" className="esperar-imagen" />
        {!okWinter && laUrl == "winterhack" && (
          <h3>
            Recuerda que para rendir el examen de Winter tienes que tener mas de
            3 retos entregados y mas de 40 presentes.
          </h3>
        )}
        {vioTodo < 99 ? (
          <>
            <h1>
              Viste el {vioTodo.toFixed(2)}% del curso, todavía falta para
              completarlo al 100%
            </h1>
          </>
        ) : (
          <>
            {cuantoFalta != "" && (
              <>
                <h3>
                  Tienen que pasar al menos 7 días para que puedas volver a
                  rendir el examen.
                </h3>
                <h1>Intentá de nuevo el {cuantoFalta}</h1>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ExamenesEsperar;
