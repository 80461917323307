import React from "react";
import "../assets/css/Carrito.css";

function CarritoPaquete(props) {
  const { nombre, productos, eliminar, color } = props;
  return (
    <>
      <div
        className="soportePaquete"
        style={{ background: productos[0].colorin }}
      >
        <p className="tituloNombre">{nombre}</p>
        {productos.map((item, i) => {
          return (
            <img
              className="img-paquete"
              key={`carritoPaquete${i}`}
              src={`https://escueladevrock.com/${item.logo}`}
              alt={item.nombre}
            />
          );
        })}

        <button
          onClick={() => {
            eliminar(nombre);
          }}
          className="btn-loco"
        >
          Borrar
        </button>
      </div>
    </>
  );
}

export default CarritoPaquete;
