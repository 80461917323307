import React from "react";
import WinterHackDescuentos from "../components/WinterHackDescuentos";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import "../assets/css/Summerhack.css";
import parse from "react-html-parser";
import PregRes from "../components/PregRes";
import Chupetin from "../assets/statics/cubitou.png";
import ChupetinPregunta from "../assets/statics/chupetinPregunta.png";
import SummerHackLogo from "../assets/statics/summerHackTitulo.png";
import employees from "../assets/statics/003-employees.svg";
import videoCamera from "../assets/statics/001-video-camera.svg";
import clock from "../assets/statics/002-clock.svg";
import mirando from "../assets/statics/mirando.png";
import mision from "../assets/statics/005-mision.svg";
import file from "../assets/statics/006-add-file.svg";
import instagram from "../assets/statics/007-instagram.svg";
import linea from "../assets/statics/008-en-linea.svg";
import certificado from "../assets/statics/009-certificado.svg";
import jsking from "../assets/statics/jsking.png";
import dino from "../assets/statics/dino.png";
import winterLogo from "../assets/statics/logo_winter.png";
import cubito from "../assets/statics/cubitoWinter.png";
import cubito01 from "../assets/statics/cubitoWinter02.png";
import cubito02 from "../assets/statics/cubitoWinter03.png";
import earlyBirds from "../assets/statics/earlyBirds.png";
import Testimonio from "../components/Testimonio";
import Equipo from "../components/Equipo";
import { Link } from "react-router-dom";
const WinterHack = () => {
  const { winter } = useContext(Contexto);

  const {
    adicional,
    alumnxs,
    bajada,
    certificados,
    descripcion,
    duracion,
    esparavos,
    insta,
    masters,
    mini,
    porquejs,
    porquejsres,
    quees,
    queesres,
    retos,
    videos,
    preguntas,
    preguntasDos,
    testimonios,
    retos_titulo,
    retos_txt,
    logica,
    equipo,
  } = winter;
  // eslint-disable-next-line
  const { history, dameMiCurso, gratis, url, toast } = winter;

  const { nombre, longDescription } = "";

  const handleCarrito = (e) => {
    console.log("Comprar");
  };
  //   props.addCarrito("summerhack2022");
  //   if (toast) {
  //     props.sendToast([toast, "info"]);
  //   } else {
  //     props.sendToast([`Agregaste SummerHack al carrito.`, "info"]);
  //   }
  //   props.history.push("/TicketSummerHack");
  // };
  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-giJF6kkoqNQ00vy+HMDP7azOuL0xtbfIcaT9wjKHr8RbDVddVHyTfAAsrekwKmP1"
        crossorigin="anonymous"
      />

      {/* <Helmet>
        <title>{`Escuela Dev Rock Curso de ${nombre}`}</title>
        <meta name="description" content={longDescription} />
      </Helmet> */}
      <section className="fafafa">
        <div className="container-fluid SHheader Primero">
          <div className="row">
            <div className="col-md-6 flexCenterOk">
              <div className="SHheader-content ">
                <img src={Chupetin} alt="" className="img-SH on" />
                <br />
                <img src={winterLogo} alt="" className="img-SHlogo" />
                <br />
                <h2 className="">{bajada}</h2>
                <p className="SHtxt">{parse(descripcion)}</p>
                {/* <Link to="/miscursos"> a ver mis cursetes</Link> */}
                <WinterHackDescuentos></WinterHackDescuentos>
                {/* <h5>{parse(bajadaPrecio)}</h5> */}

                {/* <p> {parse(mini)}</p> */}
              </div>
            </div>
            <div className="col-md-6  flexCenterOk">
              <img src={cubito} alt="" className="img-SH off" />
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid">
        <div className="row punch">
          <div className="col-md-3">
            <img src={employees} alt="" className="SH-ico" />
            <h5>Alumnxs</h5>
            <p className="SH-ico-txt">{parse(alumnxs)}</p>
          </div>
          <div className="col-md-3">
            <img src={clock} alt="" className="SH-ico" />
            <h5>Duración</h5>
            <p className="SH-ico-txt">{parse(duracion)}</p>
          </div>
          <div className="col-md-3">
            <img src={videoCamera} alt="" className="SH-ico" />
            <h5>Videos</h5>
            <p className="SH-ico-txt">{parse(videos)}</p>
          </div>
          <div className="col-md-3">
            <img src={mision} alt="" className="SH-ico" />
            <h5>Retos semanales</h5>
            <p className="SH-ico-txt">{parse(retos)}</p>
          </div>
        </div>
        <div className="row punch">
          <div className="col-md-3">
            <img src={file} alt="" className="SH-ico" />
            <h5>Contenido adicional</h5>

            <p className="SH-ico-txt">{parse(adicional)}</p>
          </div>
          <div className="col-md-3">
            <img src={linea} alt="" className="SH-ico" />
            <h5>Master Clases</h5>
            <p className="SH-ico-txt">{parse(masters)}</p>
          </div>
          <div className="col-md-3">
            <img src={certificado} alt="" className="SH-ico" />
            <h5>Certificados</h5>
            <p className="SH-ico-txt">{parse(certificados)}</p>
          </div>
          <div className="col-md-3">
            <img src={instagram} alt="" className="SH-ico" />
            <h5>Instagram</h5>
            <p className="SH-ico-txt">{parse(insta)}</p>
          </div>
        </div>
      </div>
      {/* <div className="container SHheader">
        <div className="row">
          <div className="col-md-12 ">
            <div className="SHheader-content ">
              <img src={earlyBirds} alt="" className="titEB" />
              <p className="SHtxt">{parse(logica)}</p>
            </div>
          </div>
        </div>
      </div> */}
      <section>
        <br />
        <br />
      </section>
      <section>
        <div className="container-fluid SHheaderRosa">
          <div className="row">
            <div className="col-md-6 center">
              <img src={cubito01} alt="" className="img-SH" />
            </div>
            <div className="col-md-6 flexCenter">
              <div className="SHheader-content-right SHtxt">
                <h1 className="quees">{quees}</h1>
                {parse(queesres)}
              </div>
            </div>
          </div>
        </div>
        <section className="paravosback off">
          <div className="container">
            <div className="row paravos">
              <div className="col-md-3 right">
                <img src={dino} alt="" className="img-fluid" />
              </div>
              <div className="col-md-7">
                <h2>El Winterhack es para vos si…</h2>
                {parse(esparavos)}
              </div>
            </div>
          </div>
        </section>

        <div className="container SHheader">
          <div className="row">
            <div className="col-md-6 ">
              <div className="SHheader-content ">
                <h1 className="SHsub">{parse(porquejs)}</h1>
                <p className="SHtxt">{parse(porquejsres)}</p>
              </div>
            </div>
            <div className="col-md-6 center">
              <img src={jsking} alt="" className="img-SH off" />
            </div>
          </div>
        </div>

        <section className="alumnos">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Te lo dicen lxs alumnxs</h1>
              </div>
            </div>

            <div className="row top50">
              {testimonios &&
                testimonios.map((item, i) => (
                  <Testimonio {...item}></Testimonio>
                ))}
            </div>
          </div>
        </section>

        <div className="container-fluid SHheaderRosa">
          <div className="row">
            <div className="col-md-6 center">
              <img src={cubito02} alt="" className="img-SH" />
            </div>
            <div className="col-md-6 ">
              <div className="SHheader-content-right SHtxt">
                <h1 className="quees">{retos_titulo}</h1>
                {parse(retos_txt)}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid arribaTop">
          <div className="row SHfaq">
            <div className="col-md-12 ">
              <br /> <br />
              <h2 className="b50 center SHpreguntah1">
                Otras preguntas que quizás tenés
              </h2>
            </div>
            <div className="col-md-6 ">
              {preguntas &&
                preguntas.map((item, i) => <PregRes {...item}></PregRes>)}
            </div>
            <div className="col-md-6">
              {preguntasDos &&
                preguntasDos.map((item, i) => <PregRes {...item}></PregRes>)}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1>El equipo de Escuela DevRock</h1>
            </div>
          </div>

          <div className="row top50">
            {equipo && equipo.map((item, i) => <Equipo {...item}></Equipo>)}
          </div>
        </div>
      </section>
    </>
  );
};

export default WinterHack;
