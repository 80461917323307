import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Codea from "../assets/statics/editacodea.png";
import { useContext, useEffect } from "react";
import Contexto from "../context/Contexto";

function Certificados() {
  const { cliente, buscarDataCliente } = useContext(Contexto);

  const location = useLocation();
  const [laUrl, setLaUrl] = useState("");
  const [cursones, setCursones] = useState([]);
  const [cursando, setCursando] = useState([]);
  useEffect(() => {
    setLaUrl(location.pathname.substr(14));
    buscarDataCliente(location.pathname.substr(14));
  }, []);
  let preparaRendidos = () => {
    let cursonesx = [];
    let claves = Object.keys(cliente.examenes);
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      let nop = cliente.examenes[clave];
      nop.nombre = clave;
      if (nop.aprobado) {
        cursonesx.push(nop);
      }
    }
    setCursones(cursonesx);
  };

  let prepararCursando = () => {
    let cursonesx = [];
    let claves = Object.keys(cliente.vencimientos);
    for (let i = 0; i < claves.length; i++) {
      let clave = claves[i];
      let nop = cliente.vencimientos[clave];
      nop.nombre = clave;
      //cursonesx.push(nop);
      let rana = true;

      for (let item in cursones) {
        if (cursones[item].nombre == clave) {
          rana = false;
        }
      }
      if (rana) {
        cursonesx.push(nop);
      }
      //
    }

    let checka = (clave) => {
      for (let item in cursones) {
        if (cursones[item].nombre == clave);
      }
    };
    //console.log("cursonesx:", cursonesx);

    let cursandoSinRendidos = cursonesx.filter(
      (x) => cursones.indexOf(x.nombre) === -1
    );
    setCursando(cursandoSinRendidos);
    console.log(cursandoSinRendidos, "cursones-->", cursones);
  };
  useEffect(() => {
    if (cliente) {
      console.log("cliente:", cliente);
      preparaRendidos();
      setTimeout(() => {
        prepararCursando();
      }, 1000);
    }
  }, [cliente]);
  return (
    <>
      <div className="contenedorPerfil">
        <div className="datasPerfil">
          <h1>{cliente && cliente.username}</h1>
          <h5>Cursó y aprobó:</h5>

          {cursones.map((item, i) => {
            return <li>{item.nombre}</li>;
          })}

          <h5>Y se encuentra cursando:</h5>

          {cursando.map((item, i) => {
            return <li>{item.nombre}</li>;
          })}
        </div>
        <div className="colorcito off">
          <img src={Codea} alt="CodeaRock.com" className="off codealoco" />
        </div>
      </div>
    </>
  );
}

export default Certificados;
