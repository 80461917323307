import React, { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import parse from "react-html-parser";
import earlyBirds from "../assets/statics/earlyfecha.png";
import winterearly from "../assets/statics/winterearly.png";
import winterlogicaearly from "../assets/statics/winterlogicaearly.png";
import "../assets/css/Summerhack.css";

const WinterHackDescuentos = () => {
  const {
    usuario,
    influencers,
    loguearUsuario,
    desconectarUsuario,
    cursoscomprados,
    cambiaPrecio,
  } = useContext(Contexto);
  const [precioFinal, setPrecioFinal] = useState(71500);
  const [mensaje, setMensaje] = useState("");
  const [descuentardo, setDescuentardo] = useState("");
  const [descuen, setDescuen] = useState(0);
  const [textito, setTextito] = useState("");
  const [descuentoEarly, setDescuentoEarly] = useState(0);
  const [meses, setMeses] = useState(3);
  const [precioFull, setPrecioFull] = useState(71500);
  const [descuenFinal, setDescuenFinal] = useState(0);

  const [precio, setPrecio] = useState([
    { precio: 71500, nombre: "Winterhack X3 meses 72k" },
    { precio: 128600, nombre: "Winterhack X12 meses 129k" },
  ]);

  useEffect(() => {
    calcularDescuento();
  }, [cursoscomprados]);
  const calcularDescuento = () => {
    //Esto cambiarlo el 25 a las 0hs
    let descuento = 0;
    let descuentoXCurso = 0;
    let txt = "";
    if (cursoscomprados.html) {
      if (cursoscomprados.html.estado && cursoscomprados.html.restantes > 90) {
        descuento = descuento + 5000;
        descuentoXCurso = descuentoXCurso + 5000;
        txt = txt + "/ HTML";
      }
    }
    if (cursoscomprados.js) {
      if (cursoscomprados.js.estado && cursoscomprados.js.restantes > 90) {
        descuento = descuento + 5000;
        descuentoXCurso = descuentoXCurso + 5000;

        txt = txt + "/ JS";
      }
    }
    if (cursoscomprados.jspractico) {
      if (
        cursoscomprados.jspractico.estado &&
        cursoscomprados.jspractico.restantes > 90
      ) {
        descuento = descuento + 5000;
        descuentoXCurso = descuentoXCurso + 5000;

        txt = txt + "/ JS Practico";
      }
    }
    if (cursoscomprados.react) {
      if (
        cursoscomprados.react.estado &&
        cursoscomprados.react.restantes > 90
      ) {
        descuento = descuento + 5000;
        descuentoXCurso = descuentoXCurso + 5000;

        txt = txt + "/ React";
      }
    }
    if (cursoscomprados.firebase) {
      if (
        cursoscomprados.firebase.estado &&
        cursoscomprados.firebase.restantes > 90
      ) {
        descuento = descuento + 5000;
        descuentoXCurso = descuentoXCurso + 5000;

        txt = txt + " / Firebase";
      }
    }
    let leyenda = "";

    //21500 o 38600
    if (descuentoXCurso > 0) {
      leyenda = `Te descontamos $5000 por cada uno de estos cursos que ya compraste y <strong>no</strong> están vencidos: ${txt}.<br> ¡Wow! Te llevás un re descuento de:  </strong>
      `;
    } else {
      leyenda =
        "No tenés habilitado ninguno de los cursos del programa, si tuvieras te descontaría 5.000 pesos por cada uno.<br>Pero afortunadamente tenés 30% Off en el ticket del Winter por comprar en <strong>Early Birds.</strong> <br><strong>Descuento total:  </strong>";
    }
    // leyenda =
    //   leyenda +
    //primer setero de precio final
    setPrecioFinal(71500 - descuento);

    setDescuen(descuento);
    setDescuenFinal(descuento);
    setMensaje(` ${leyenda}`);
  };
  const handleChange = (e) => {
    setTextito(e.target.value);
  };

  let comprarlo = () => {
    cambiaPrecio("/winterhack23", precioFinal);
  };
  let dameWinter = (e) => {
    setMeses(3);
    let es = e.target.name;

    e.target.classList.add("dale");
    const $select = document.querySelector("#combino");
    if (es == "winter") {
      setPrecioFull(71500);
      setPrecioFinal(71500 - descuen);
      // Inicio sesion hoy habitos saludables 7/6

      setPrecio([
        { precio: 71500, nombre: "Winterhack X3 meses 71k" },
        { precio: 128600, nombre: "Winterhack X12 meses 129k" },
      ]);
      if ($select.value == 138600) $select.value = "86500";

      document.getElementById("winterlogicax").classList.remove("dale");
    } else {
      document.getElementById("winterx").classList.remove("dale");

      setPrecioFull(86500);
      setPrecioFinal(86500 - descuen - 21500);

      setPrecio([
        { precio: 86500, nombre: "Winterhack + Lógica X3 meses 65k" },
        { precio: 138600, nombre: "Winterhack + Lógica X12 meses 100k" },
      ]);
      if ($select.value == 128600) $select.value = "71500";
    }
  };

  let hancdleChange = (e) => {
    // esto es para Winter 3 meses solo
    if (e.target.value == 71500) {
      setPrecioFinal(e.target.value - descuen);
      setMeses(3);
      setDescuenFinal(descuen);
    }
    //esto es para winter solo 12 meses
    if (e.target.value == 128600) {
      setMeses(12);
      setPrecioFinal(e.target.value - descuen);
      setDescuenFinal(descuen);
    }
    // esto es winter con logica 3 meses
    // if (e.target.value == 86500) {
    //   setPrecioFinal(e.target.value - descuen - 21500);
    //   setDescuenFinal(descuen + 21500);

    //   setMeses(3);
    // }
    // if (e.target.value == 138600) {
    //   setPrecioFinal(e.target.value - descuen - 38600);
    //   setDescuenFinal(descuen + 38600);

    //   setMeses(12);
    // }
    //Esto siempre va asi!
    setPrecioFull(e.target.value);
  };
  return (
    <>
      <div className="comboOferta centerX">
        {/* <img src={earlyBirds} alt="" className="titEB" /> */}
        <br /> <br />
        {/* <h3>Seleccioná una opción:</h3> */}
        {/* <img
          src={winterearly}
          alt=""
          className="titEB wlx dale"
          name="winter"
          onClick={dameWinter}
          id="winterx"
        /> */}
        {/* <br />
        <img
          src={winterlogicaearly}
          alt=""
          name="winterlogica"
          className="titEB wl"
          onClick={dameWinter}
          id="winterlogicax"
        />
        <br /> */}
        {/* <select name="select" onChange={hancdleChange} id="combino">
          <option value={precio[0].precio}>{precio[0].nombre}</option>
          <option value={precio[1].precio}>{precio[1].nombre}</option>
        </select> */}
        <>
          <h4 className="precioViejo">
            {precioFull < precioFinal}
            {<del>AR$ {precioFull}</del>}
          </h4>
          <h1 className="precioFinal">AR$ {precioFinal}</h1>

          <p>
            {parse(mensaje)}{" "}
            <strong>
              <h6>AR$ ${descuenFinal} </h6>
            </strong>
          </p>
        </>
        <p>
          Precio completo por los {meses} meses <br></br>
          <strong>Pagalos hasta en 3 cuotas sin interés</strong> <br />
          Si estas en el exterior dentro de esta opcion podes pagar con paypal
        </p>
        {usuario !== null ? (
          <>
            <button
              className="btn btn-primary"
              onClick={() => {
                comprarlo();
              }}
            >
              COMPRAR CON TARJETA 💳
            </button>
            <br />
            <div className="transferencia">
              <p>
                <strong>
                  25% Off transferencia bancaria <br /> te queda en: AR${" "}
                  {(precioFinal / 100) * 75}{" "}
                </strong>{" "}
                <br />
                Alias:<strong> codearock</strong> <br />
                Enviar comprobante a <br />
                <a href="mailto:rana@codearock.com" className="blancox">
                  <strong>rana@codearock.com</strong>{" "}
                </a>{" "}
                <br /> para que te den el alta.
              </p>
              <p>
                Consultá al mismo mail por planes en 3 cuotas sin tarjeta solo
                por transferencia bancaria.
              </p>
            </div>
            <br /> <br />
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                desconectarUsuario();
              }}
            >
              Desconectame
            </button>
          </>
        ) : (
          <>
            <p>
              Logueate con tu usuario para poder sumarte al programa. Si ya
              tenés alguno de los cursos incluidos, vas a ver un descuento en el
              precio final por cada uno.
            </p>
            <button
              className="SHbtnComprar"
              onClick={() => {
                loguearUsuario();
              }}
            >
              LOGUEAME Y DESCONTAME!
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default WinterHackDescuentos;
