import React, { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import parse from "react-html-parser";
import "../assets/css/Summerhack.css";

import { useNavigate } from "react-router-dom";

const SummerDescuentos = () => {
  const {
    usuario,
    influencers,
    loguearUsuario,
    desconectarUsuario,
    cursoscomprados,
    cambiaPrecio,
  } = useContext(Contexto);
  const [precioFinal, setPrecioFinal] = useState(150000);
  const [mensaje, setMensaje] = useState("¡Early Bird 30%OFF!");
  const [descuentardo, setDescuentardo] = useState(45000);
  const [descuen, setDescuen] = useState(45000);
  const [textito, setTextito] = useState("");
  const [descuentoEarly, setDescuentoEarly] = useState(45000);
  const [meses, setMeses] = useState(3);
  const [precioFull, setPrecioFull] = useState(150000);
  const [descuenFinal, setDescuenFinal] = useState(45000);
  const navigate = useNavigate();

  useEffect(() => {
    calcularDescuento();
    console.log(cursoscomprados);
  }, [cursoscomprados]);
  const calcularDescuento = () => {
    console.log("llama a calcular descuento");
    if (
      cursoscomprados.summerhack23 ||
      cursoscomprados.summerhack2022 ||
      cursoscomprados.summerhack24 ||
      cursoscomprados.summerhack2021 ||
      cursoscomprados.summerhack2021feb ||
      cursoscomprados.winterhack ||
      cursoscomprados.winterhack23 ||
      cursoscomprados.easterhack
    ) {
      setPrecioFinal(80000);
      setDescuentardo(70000);
      console.log("LE descontamos perri");
      setMensaje(
        " Estás comprando con el descuento deEsrlyBird 30%OFF y, ¡wow! <br> Te restamos un 20% más por ser exalumnx del programa. "
      );
    } else {
      setPrecioFinal(105000);
      setDescuentardo(45000);
    }
  };

  let comprarlo = () => {
    cambiaPrecio("/winterhack24", precioFinal);
    navigate("/carrito");
  };

  return (
    <>
      <div className="comboOferta centerX comprarStick">
        <>
          <h4 className="precioViejo">
            {precioFull < precioFinal}
            {<del>AR$ {precioFull}</del>}
          </h4>
          <h1 className="precioFinal">AR$ {precioFinal}</h1>

          <p>
            {parse(mensaje)}{" "}
            <strong>
              <h6>Te ahorrás AR${descuentardo} </h6>
            </strong>
          </p>
        </>
        <p>
          Precio completo por los 12 meses <br></br>
          <strong>Pagalo hasta en 6 cuotas sin interés</strong> <br />
          Si estás en el exterior dentro de esta opción podés pagar con PayPal
        </p>
        {usuario !== null ? (
          <>
            <button
              className="btn btn-primary"
              onClick={() => {
                comprarlo();
                grisea;
              }}
            >
              COMPRAR TARJETA / TRANSFERENCIA / PAYPAL
            </button>
            <br />
            <br /> <br />
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                desconectarUsuario();
              }}
            >
              Desconectame
            </button>
          </>
        ) : (
          <>
            <p>
              Logueate con tu usuario para poder sumarte al programa. Si ya
              hiciste el Summer o el Winter tenes un 30% más
            </p>
            <button
              className="SHbtnComprar"
              onClick={() => {
                loguearUsuario();
              }}
            >
              LOGUEAME Y DESCONTAME!
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default SummerDescuentos;
