import React from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import Contexto from "../context/Contexto";
const Paquetes = (props) => {
  const navigate = useNavigate();
  const { autoCarga, Griseado } = useContext(Contexto);
  const { btn, link, texto, titulo, cursos, _color_ } = props;
  const logoCursos = () => {
    let cursin = cursos.split("-");
    let table = [];
    for (const prop in cursin) {
      table.push(
        <img
          src={`https://escueladevrock.com/statics/prod-${cursin[prop]}.png`}
          className="cursoDePack"
          alt=""
          key={prop}
        />
      );
    }

    return table;
  };

  const handleComprar = (e) => {
    let mandamo = link.replace("/carrito#", "");
    let decode = atob(mandamo);
    autoCarga(decode);

    Griseado(link);
  };
  return (
    <>
      <div className="carousel-item-pack" style={{ backgroundColor: _color_ }}>
        {/* <img src={logo} alt="" className="carousel-item-pack__img" /> */}
        <div className="carousel-item-pack__details">
          <p className="carousel-item-pack__details--title">{titulo}</p>

          <div className="tiraDeImagenCursos">{logoCursos()}</div>
          <p className="carousel-item-pack__details--subtitle">{texto}</p>

          <div className="actions">
            <button type="button" onClick={handleComprar} className="btn-loco">
              {btn}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Paquetes;
