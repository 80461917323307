import React from "react";
import UNO from "../assets/statics/click.png";
import DOS from "../assets/statics/click-b.png";
import MercadoPagoLite from "../components/MercadoPagoLite";
function Coming() {
  //alert(window.ethereum);
  const handleMouseEnter = (e) => {
    e.target.src = DOS;
  };
  const handleMouseLeave = (e) => {
    e.target.src = UNO;
  };
  return (
    <>
      <div className="comin">
        {/* <img
          src={UNO}
          alt=""
          className="cli"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        /> */}
        <MercadoPagoLite
          precio={2000}
          email={"jonatan@jona.com"}
        ></MercadoPagoLite>
      </div>
    </>
  );
}

export default Coming;
